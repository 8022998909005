import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  getProjectDetailTable1,
  getProjectDetailTable2,
  getProjectDetailTable3,
  getSimulationScenarioSunbrust,
  getscenarioFilters,
} from 'src/redux/async/ice/scenariocomparision/scenario.async';
import { getSimulationScenario } from 'src/redux/async/ice/scenariocomparision/scenario.async';

const initialState = {
  projectData: [],
  allProjectData: [],
  projectDataLoader: false,
  projectDataError: '',
  project1Loader: false,
  project2Loader: false,
  project3Loader: false,
  project1Data: [],
  project2Data: [],
  project3Data: [],
  sunbrustLoader: false,
  sunbrustInfo: [],
  scenarioFilterLoader: false,
  scenarioFilters: {},
};

export const scenarioComparisionSlice = createSlice({
  name: 'scenarioComparisionSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getSimulationScenario.pending), (state, action) => {
      state.projectDataLoader = true;
    });
    // LOADER
    builder.addMatcher(isAnyOf(getProjectDetailTable1.pending), (state, action) => {
      state.project1Loader = true;
    });
    builder.addMatcher(isAnyOf(getProjectDetailTable2.pending), (state, action) => {
      state.project2Loader = true;
    });
    builder.addMatcher(isAnyOf(getProjectDetailTable3.pending), (state, action) => {
      state.project3Loader = true;
    });
    builder.addMatcher(isAnyOf(getSimulationScenarioSunbrust.pending), (state, action) => {
      state.sunbrustLoader = true;
    });
    builder.addMatcher(isAnyOf(getscenarioFilters.pending), (state, action) => {
      state.scenarioFilterLoader = true;
    });

    // DATA
    builder.addMatcher(isAnyOf(getProjectDetailTable1.fulfilled), (state, action) => {
      state.project1Loader = false;
      state.project1Data = action.payload.data;
    });
    builder.addMatcher(isAnyOf(getProjectDetailTable2.fulfilled), (state, action) => {
      state.project2Loader = false;
      state.project2Data = action.payload.data;
    });
    builder.addMatcher(isAnyOf(getProjectDetailTable3.fulfilled), (state, action) => {
      state.project3Loader = false;
      state.project3Data = action.payload.data;
    });
    builder.addMatcher(isAnyOf(getSimulationScenario.fulfilled), (state, action) => {
      state.projectDataLoader = false;
      state.allProjectData = action.payload;
    });
    builder.addMatcher(isAnyOf(getSimulationScenarioSunbrust.fulfilled), (state, action) => {
      state.sunbrustLoader = false;
      state.sunbrustInfo = action.payload.data;
    });
    builder.addMatcher(isAnyOf(getscenarioFilters.fulfilled), (state, action) => {
      state.scenarioFilterLoader = false;
      state.scenarioFilters = action.payload.data;
    });
    // ERROR
    builder.addMatcher(isAnyOf(getProjectDetailTable1.rejected), (state, action) => {
      state.project1Loader = false;
    });
    builder.addMatcher(isAnyOf(getProjectDetailTable2.rejected), (state, action) => {
      state.project2Loader = false;
    });
    builder.addMatcher(isAnyOf(getProjectDetailTable3.rejected), (state, action) => {
      state.project3Loader = false;
    });
    builder.addMatcher(isAnyOf(getSimulationScenario.rejected), (state, action) => {
      state.projectDataLoader = false;
    });
    builder.addMatcher(isAnyOf(getSimulationScenarioSunbrust.rejected), (state, action) => {
      state.sunbrustLoader = false;
    });
    builder.addMatcher(isAnyOf(getscenarioFilters.rejected), (state, action) => {
      state.scenarioFilterLoader = true;
    });
  },
  reducers: {
    emptyProjectTable: (state, action) => {
      state.project1Data = [];
      state.project2Data = [];
      state.project3Data = [];
    },
  },
});

export const { emptyProjectTable } = scenarioComparisionSlice.actions;

export default scenarioComparisionSlice.reducer;
