import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filters: {
    formData: {
      selectedUser: '',
      selectedl1: '',
      selectedl2: '',
      selectedl3: '',
      selectedVendor: '',
    },
    searchText: '',
  },
}

export const skuFiltersSlice = createSlice({
  name: 'sku-filters',
  initialState,
  reducers: {
    setSkuFilterValues: (state, action) => {
      state.filters = action.payload
    },
    clearSkuFilters: () => initialState,
  },
})

export const { setSkuFilterValues, clearSkuFilters } = skuFiltersSlice.actions

export default skuFiltersSlice.reducer
