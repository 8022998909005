import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { updateProjectDataAsync } from 'src/redux/async/othercost/otherCostAsync';

const initialState = {
    updateProjecloader: false,
    updateProjectData: '',
    updateProjecerror: '',
}

export const updateProjecDataSlice = createSlice({
    name: 'updateProject',
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(updateProjectDataAsync.pending), (state) => {
            state.updateProjecloader = true
        })
        builder.addMatcher(isAnyOf(updateProjectDataAsync.fulfilled), (state, action) => {
            state.updateProjecloader = false
            state.updateProjectData = action.payload.data
        })
        builder.addMatcher(
            isAnyOf(updateProjectDataAsync.rejected),
            (state, action) => {
                state.updateProjecloader = false
                state.updateProjecerror = action.payload
            },
        )
    },
    reducers: {
        reset: () => initialState,
    },
})

export const { reset } = updateProjecDataSlice.actions

export default updateProjecDataSlice.reducer
