
import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getLineChart } from 'src/redux/async';

const initialState = {
  lineChartDataLoader: false,
  lineChartDataError: '',
  lineChartData_state: [],
}

export const scoreCardlineChartDataSlice = createSlice({
    name:"scoreCardlineChartData",
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(getLineChart.pending), (state) => {
          state.lineChartDataLoader = true;
        });
        builder.addMatcher(isAnyOf(getLineChart.fulfilled), (state, action) => {
          state.lineChartDataLoader = false;
          state.lineChartData_state = action.payload;
          state.lineChartDataError = "";
        });
        builder.addMatcher(isAnyOf(getLineChart.rejected), (state, action) => {
          state.lineChartDataLoader = false;
          state.lineChartDataError = action.payload;
        });
      },
})

export default scoreCardlineChartDataSlice.reducer;