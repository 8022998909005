import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  getCountries,
  getCategory,
  getSubCategory,
  getGroupCategory,
  getcountryWiseProjectAsync,
  getSkuFilters,
  getVendorFilterData,
} from 'src/redux/async/ice/overviewasync'

const initialState = {
  countries: [],
  category: [],
  subCategory: [],
  groupCategory: [],
  vendorFilterData: [],
  skuData: [],
  countryWiseProjectData: [],
  filterError: '',
  fiterLoader: false,
  chartLoader: false,
  chartError: '',
}

export const iceOverviewFilterSlice = createSlice({
  name: 'iceOverviewFilter',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getCountries.pending,
        getCategory.pending,
        getSubCategory.pending,
        getGroupCategory.pending,
        getSkuFilters.pending,
        getVendorFilterData.pending,
      ),
      (state) => {
        state.fiterLoader = true
      },
    )
    builder.addMatcher(isAnyOf(getcountryWiseProjectAsync.pending), (state) => {
      state.chartLoader = true
    })

    builder.addMatcher(isAnyOf(getCountries.fulfilled), (state, action) => {
      state.fiterLoader = false
      state.countries = action.payload
      state.filterError = ''
    })
    builder.addMatcher(isAnyOf(getGroupCategory.fulfilled), (state, action) => {
      state.fiterLoader = false
      state.filterError = ''
      state.groupCategory = action.payload
    })
    builder.addMatcher(isAnyOf(getSubCategory.fulfilled), (state, action) => {
      state.fiterLoader = false
      state.filterError = ''
      state.subCategory = action.payload
    })
    builder.addMatcher(isAnyOf(getCategory.fulfilled), (state, action) => {
      state.fiterLoader = false
      state.category = action.payload
      state.filterError = ''
    })

    builder.addMatcher(isAnyOf(getSkuFilters.fulfilled), (state, action) => {
      state.fiterLoader = false
      state.skuData = action.payload
      state.filterError = ''
    })

    builder.addMatcher(isAnyOf(getVendorFilterData.fulfilled), (state, action) => {
      state.fiterLoader = false
      state.vendorFilterData = action.payload
      state.filterError = ''
    })

    builder.addMatcher(isAnyOf(getcountryWiseProjectAsync.fulfilled), (state, action) => {
      state.chartLoader = false
      state.countryWiseProjectData = action.payload
    })

    builder.addMatcher(
      isAnyOf(
        getCountries.rejected,
        getCategory.rejected,
        getSubCategory.rejected,
        getGroupCategory.rejected,
        getcountryWiseProjectAsync.rejected,
        getSkuFilters.rejected,
        getVendorFilterData.rejected,
      ),
      (state, action) => {
        state.fiterLoader = false
        state.filterError = action.payload
      },
    )
  },
})

export default iceOverviewFilterSlice.reducer
