import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getprojectDetails } from "./rfi-rfp.async";

const initialState = {
  projectDetailsLoader: false,
  projectDetailsError: "",
  projectDetails_state: [],
};

export const projectDetailsSlice = createSlice({
  name: "projectDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getprojectDetails.pending), (state) => {
      state.projectDetailsLoader = true;
    });
    builder.addMatcher(isAnyOf(getprojectDetails.fulfilled), (state, action) => {
      state.projectDetailsLoader = false;
      state.projectDetails_state = action.payload;
      state.projectDetailsError = "";
    });
    builder.addMatcher(isAnyOf(getprojectDetails.rejected), (state, action) => {
      state.projectDetailsLoader = false;
      state.projectDetailsError = action.payload;
    });
  },
  reducers: {
    emptyprojectDetailsSlice: (state) => {
      state.projectDetailsLoader = false;
      state.projectDetailsError = "";
      state.projectDetails_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default projectDetailsSlice.reducer;
