import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getProductsTree } from "src/redux/async/bomAnalysis/bomAnalysisAsync";

const initialState = {
  treeLoader: false,
  treeError: "",
  treeData: [],
};

export const bomAnalysisTreeDataSlice = createSlice({
  name: "bom-analysis/tree-data",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getProductsTree.pending), (state) => {
      state.treeLoader = true;
    });
    builder.addMatcher(isAnyOf(getProductsTree.fulfilled), (state, action) => {
      state.treeLoader = false;
      state.treeData = action.payload;
      state.treeError = "";
    });
    builder.addMatcher(isAnyOf(getProductsTree.rejected), (state, action) => {
      state.treeLoader = false;
      state.treeError = action.payload;
    });
  },
});

export default bomAnalysisTreeDataSlice.reducer;