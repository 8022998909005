import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getCommodityDataAsync } from 'src/services/shouldCostAIBased.services'

const initialState = {
  commodityDataLoader: false,
  commodityData: [],
  commodityLastUpdatedDate: '',
  commodityDataError: '',
}

export const getCommodityDataSlice = createSlice({
  name: 'get-commodity-data',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getCommodityDataAsync.pending), (state) => {
      state.commodityDataLoader = true
    })
    builder.addMatcher(isAnyOf(getCommodityDataAsync.fulfilled), (state, action) => {
      state.commodityDataLoader = false
      state.commodityData = action.payload.data
      state.commodityLastUpdatedDate = action.payload.last_updated_date
    })
    builder.addMatcher(isAnyOf(getCommodityDataAsync.rejected), (state, action) => {
      state.commodityDataLoader = false
      state.commodityDataError = action.payload.message
    })
  },
})

export default getCommodityDataSlice.reducer
