import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getProjectWiseDataPie } from 'src/redux/async/ice/overviewasync'

const initialState = {
  pieChartLoader: false,
  pieChartData: [],
  piechartError: '',
}

const piechartSlice = createSlice({
  name: 'piechartSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getProjectWiseDataPie.pending), (state) => {
      state.pieChartLoader = true
    })

    builder.addMatcher(isAnyOf(getProjectWiseDataPie.fulfilled), (state, action) => {
      state.pieChartLoader = false
      state.pieChartData = action.payload
    })

    builder.addMatcher(isAnyOf(getProjectWiseDataPie.rejected), (state, action) => {
      state.pieChartLoader = false
      action.piechartError = action.payload
    })
  },
})

export default piechartSlice.reducer
