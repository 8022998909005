import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getTemplates } from "./rfi-rfp.async";

const initialState = {
    templateDetailsLoader: false,
    templateDetailsError: "",
  templateDetails_state: [],
};

export const templateDetailsSlice = createSlice({
  name: "templateDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getTemplates.pending), (state) => {
      state.templateDetailsLoader = true;
    });
    builder.addMatcher(isAnyOf(getTemplates.fulfilled), (state, action) => {
      state.templateDetailsLoader = false;
      state.templateDetails_state = action.payload;
      state.templateDetailsError = "";
    });
    builder.addMatcher(isAnyOf(getTemplates.rejected), (state, action) => {
      state.templateDetailsLoader = false;
      state.templateDetailsError = action.payload;
    });
  },
  reducers: {
    emptytemplateDetailsSlice: (state) => {
      state.templateDetailsLoader = false;
      state.templateDetailsError = "";
      state.templateDetails_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default templateDetailsSlice.reducer;
