import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getSupplierInformation } from './rfi-rfp.async'

const initialState = {
  supplierInfomationLoader: false,
  supplierInformationData: [],
  supplierInformationError: '',
}

export const supplierInformationSlice = createSlice({
  name: 'Supplier-Information',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getSupplierInformation.pending), (state) => {
      state.supplierInfomationLoader = true
    })
    builder.addMatcher(isAnyOf(getSupplierInformation.fulfilled), (state, action) => {
      state.supplierInfomationLoader = false
      state.supplierInformationData = action.payload
    })
    builder.addMatcher(isAnyOf(getSupplierInformation.rejected), (state, action) => {
      state.supplierInfomationLoader = false
      state.supplierInformationError = action.payload.message
    })
  },
})

export default supplierInformationSlice.reducer
