import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getEmbedPowerBiTokenAsync } from 'src/redux/async/ManageMentReport/ManageMentReport.async'

const initialState = {
  managementrepoertloader: false,
  PowerBIToken: [],
}

export const ManageMentReportSlice = createSlice({
  name: 'ManageMentReport',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getEmbedPowerBiTokenAsync.pending), (state) => {
      state.managementrepoertloader = true
    })
    builder.addMatcher(isAnyOf(getEmbedPowerBiTokenAsync.fulfilled), (state, action) => {
      state.managementrepoertloader = false
      let accessToken = action.payload?.accessToken
      state.PowerBIToken = {
        accessToken: accessToken,
        embedUrl: Boolean(accessToken) && action.payload.embedUrl[0]?.embedUrl,
        reportId: Boolean(accessToken) && action.payload.embedUrl[0]?.reportId,
      }
    })
    builder.addMatcher(isAnyOf(getEmbedPowerBiTokenAsync.rejected), (state, action) => {
      state.managementrepoertloader = false
    })
  },
  reducers: {
    PowerBITokenReset: () => initialState,
  },
})

export const { PowerBITokenReset } = ManageMentReportSlice.actions

export default ManageMentReportSlice.reducer
