import { createAsyncThunk } from "@reduxjs/toolkit"
import { AxiosClient } from "src/redux/AxiosClient"

export const getProdcutCategoryGroupAsync = createAsyncThunk(
    'massUpload/prodcutCategoryGroup',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('GET', '/v1/landing/ah3', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )
  
export const getProductCategoriesAsync = createAsyncThunk(
    'massUpload/productCategories',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('GET', '/v1/landing/ah4', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const getProductSubCategoriesAsync = createAsyncThunk(
    'massUpload/productSubCategories',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v1/landing/ah5', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const getProductRowsAsync = createAsyncThunk(
    'massUpload/productRows',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('GET', '/v1/landing/rowspid', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const getTemplatesLibraryAsync = createAsyncThunk(
    'massUpload/templatesLibrary',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', 'v1/template/master', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const deleteTemplateAsync = createAsyncThunk(
    'massUpload/deleteTemplate',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', 'v2/template/delete', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const getElementsAsync = createAsyncThunk(
    'massUpload/elements',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v1/getElement', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const getConverionCountriesAsync = createAsyncThunk(
    'massUpload/converionCountries',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('GET', '/v1/conversion/countries', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const getUseTemplateAsync = createAsyncThunk(
    'massUpload/useTemplate',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v1/template', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const markTemplateAsync = createAsyncThunk(
    'massUpload/markTemplate',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v2/template/master', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const uploadTemplateAsync = createAsyncThunk(
    'massUpload/uploadTemplate',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v2/template/create/data', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const uploadSimplifiedTemplateAsync = createAsyncThunk(
    'massUpload/uploadSimplifiedTemplate',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v2/template/create/simplified/data', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const uploadTemplateDataAsync = createAsyncThunk(
    'massUpload/uploadTemplateData',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v2/template/create', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const uploadTemplateStatusAsync = createAsyncThunk(
    'massUpload/uploadTemplateStatus',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v2/template/status', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )
  
export const deleteTemplateDataAsync = createAsyncThunk(
    'massUpload/deleteTemplateData',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v2/template/delete/Data', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const deleteTemplateDataSimAsync = createAsyncThunk(
    'massUpload/deleteTemplateDataSim',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v2/simplifiedtemplate/delete/Data', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const simplifiedHandlerAsync = createAsyncThunk(
    'massUpload/simplifiedHandler',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v2/template/simplified', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )
  
  export const readFileHandlerAsync = createAsyncThunk(
    'massUpload/readFileHandler',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v1/readFile', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

  export const getMassTemplateCalculationAsync = createAsyncThunk(
    'massUpload/massTemplateCalculation',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v1/createProject/allMassUpload', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )
  export const getSimplifiedTemplateCalculationAsync = createAsyncThunk(
    'massUpload/massTemplateCalculation',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v1/createProject/allSimplifiedProjects', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )
  

  export const getExistingTemplatesAsync = createAsyncThunk(
    'massUpload/getExistingTemplates',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('GET', `/v1/existingTemplate/rows?page=1&text=&group=&category=${payload || ""}&subcategory=&country=&status=`, payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

  