
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getKipsMonitorResponses } from "./rfi-rfp.async";

const initialState = {
    monitorResponseKipsLoader: false,
    monitorResponseKipsError: "",
  monitorResponseKips_state: [],
};

export const monitorResponseKipsSlice = createSlice({
  name: "monitorResponseKips",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getKipsMonitorResponses.pending), (state) => {
      state.monitorResponseKipsLoader = true;
    });
    builder.addMatcher(isAnyOf(getKipsMonitorResponses.fulfilled), (state, action) => {
      state.monitorResponseKipsLoader = false;
      state.monitorResponseKips_state = action.payload;
      state.monitorResponseKipsError = "";
    });
    builder.addMatcher(isAnyOf(getKipsMonitorResponses.rejected), (state, action) => {
      state.monitorResponseKipsLoader = false;
      state.monitorResponseKipsError = action.payload;
    });
  },
  reducers: {
    emptymonitorResponseKipsSlice: (state) => {
      state.monitorResponseKipsLoader = false;
      state.monitorResponseKipsError = "";
      state.monitorResponseKips_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default monitorResponseKipsSlice.reducer;
