import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  getMarginOverhead,
  deleteProjectDataOtherCostAsync,
  updateProjectDataOtherCostAsync,
  checkDeleteProjectDataOtherCostAsync,
  finalFormulaasync,
} from 'src/redux/async/ice/buildshouldcostdetails/othercostasync'
import { updateProjectDataAsync } from 'src/redux/async/othercost/otherCostAsync'

const initialState = {
  OverheadMarginSubsectionData: [],
  OverheadMarginFinalFormula: {},
  OverheadMarginLoader: false,
  OverheadMarginError: '',
  deleteDetails: {
    deleteLoader: false,
    deleteState: [],
    deleteError: '',
  },
  updateDetails: {
    updateLoader: false,
    updateState: [],
    updateError: '',
  },
  addDetails: {
    addLoader: false,
    addState: [],
    addError: '',
  },
}

export const otherCostOverheadMarginSlice = createSlice({
  name: 'othercostOverheadMargin',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getMarginOverhead.pending), (state) => {
      state.OverheadMarginLoader = true
    })

    builder.addMatcher(isAnyOf(getMarginOverhead.fulfilled), (state, action) => {
      state.OverheadMarginLoader = false
      state.OverheadMarginSubsectionData = [...action.payload.data.subsection]
      state.OverheadMarginFinalFormula = { ...action.payload.data.subsectionFinalFormula }
    })

    builder.addMatcher(isAnyOf(getMarginOverhead.rejected), (state, action) => {
      state.OverheadMarginLoader = false
      state.OverheadMarginError = action.payload
    })

    builder.addMatcher(isAnyOf(checkDeleteProjectDataOtherCostAsync.pending), (state, action) => {
      state.deleteDetails.deleteLoader = true
    })
    builder.addMatcher(isAnyOf(checkDeleteProjectDataOtherCostAsync.fulfilled), (state, action) => {
      state.deleteDetails.deleteLoader = false
      state.deleteDetails.deleteState = action.payload
    })
    builder.addMatcher(isAnyOf(checkDeleteProjectDataOtherCostAsync.rejected), (state, action) => {
      state.deleteDetails.deleteLoader = false
      state.deleteDetails.deleteError = action.payload.message
    })

    builder.addMatcher(isAnyOf(updateProjectDataOtherCostAsync.pending), (state, action) => {
      state.updateDetails.updateLoader = true
    })
    builder.addMatcher(isAnyOf(updateProjectDataOtherCostAsync.fulfilled), (state, action) => {
      state.updateDetails.updateLoader = false
      state.updateDetails.updateState = action.payload
    })
    builder.addMatcher(isAnyOf(updateProjectDataOtherCostAsync.rejected), (state, action) => {
      state.updateDetails.updateLoader = false
      state.updateDetails.updateError = action.payload.message
    })

    builder.addMatcher(isAnyOf(updateProjectDataAsync.pending), (state, action) => {
      state.addDetails.addLoader = true
    })
    builder.addMatcher(isAnyOf(updateProjectDataAsync.fulfilled), (state, action) => {
      state.addDetails.addLoader = false
      state.addDetails.addState = action.payload
    })
    builder.addMatcher(isAnyOf(updateProjectDataAsync.rejected), (state, action) => {
      state.addDetails.addLoader = false
      state.addDetails.addError = action.payload.message
    })
  },
  reducers: {
    deleteData: (state) => {
      state.deleteDetails.deleteLoader = false
      state.deleteDetails.deleteState = []
      state.deleteDetails.deleteError = ''
    },
    updateData: (state) => {
      state.updateDetails.updateLoader = false
      state.updateDetails.updateState = []
      state.updateDetails.updateError = ''
    },
    addData: (state) => {
      state.addDetails.addLoader = false
      state.addDetails.addState = []
      state.addDetails.addError = ''
    },
  },
})

export const { deleteData, updateData, addData } = otherCostOverheadMarginSlice.actions
export default otherCostOverheadMarginSlice.reducer
