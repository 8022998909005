import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const getProjectDetailTable1 = createAsyncThunk(
  'shouldcost/projectDataForScenario1',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/scenarioProjectData`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
export const getProjectDetailTable2 = createAsyncThunk(
  'shouldcost/projectDataForScenario2',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/scenarioProjectData`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
export const getProjectDetailTable3 = createAsyncThunk(
  'shouldcost/projectDataForScenario3',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/scenarioProjectData`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getSimulationScenario = createAsyncThunk(
  'shouldcost/simulationScenarioAllProject',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/simulationScenarioAllProject`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
export const getSimulationScenarioSunbrust = createAsyncThunk(
  'scenariocomparison/getSimulationScenarioSunbrust',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/scenarioProjectData/sunburst`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
export const getscenarioFilters = createAsyncThunk(
  'scenariocomparison/getscenarioFilters',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', `/overview/scenarioFilters`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getScenarioProjectCountries = createAsyncThunk(
  'scenariocomparison/filterCountry',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', '/v1/scenarioProject/country', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
