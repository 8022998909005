import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getBuildShouldCostRowsAsync } from 'src/redux/async/libraries/buildshouldcost'

const initialState = {
  shouldCostLoader: false,
  shouldCostData: [],
  shouldCostError: '',
}

export const shouldCostSlice = createSlice({
  name: 'shouldCostData',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getBuildShouldCostRowsAsync.pending), (state) => {
      state.shouldCostLoader = true
    })

    builder.addMatcher(isAnyOf(getBuildShouldCostRowsAsync.fulfilled), (state, action) => {
      state.shouldCostLoader = false
      state.shouldCostData = action.payload
      state.shouldCostError = ''
    })

    builder.addMatcher(isAnyOf(getBuildShouldCostRowsAsync.rejected), (state, action) => {
      state.shouldCostLoader = false
      state.shouldCostError = action.payload
    })
  },
})

export default shouldCostSlice.reducer
