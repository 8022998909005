import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { createMachineAsync } from "src/redux/async/libraries/machine";

const initialState = {
  createmachineloader: false,
  createmachineerror: "",
  createmachineconfirm: "",
};

export const createmachineSlice = createSlice({
  name: "createmachine",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(createMachineAsync.pending), (state) => {
      state.createmachineloader = true;
    });
    builder.addMatcher(isAnyOf(createMachineAsync.fulfilled), (state, action) => {
      state.createmachineloader = false;
      state.createmachineconfirm = action.payload
    });
    builder.addMatcher(isAnyOf(createMachineAsync.rejected), (state, action) => {
      state.createmachineloader = false;
      state.createmachineerror = action.payload;
    });
  },
    reducers: {
    reset: () => initialState
    }
});

export const { reset } = createmachineSlice.actions

export default createmachineSlice.reducer;