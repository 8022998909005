import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const getProjectDetailAsync = createAsyncThunk(
  'shouldcost/project-details',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/project`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
