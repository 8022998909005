import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  editedProjectId: null,
}

export const editShouldCostSlice = createSlice({
  name: 'show-edited-project-selected',
  initialState,
  reducers: {
    setEditedProjectId: (state, action) => {
      state.editedProjectId = action.payload
    },
    clearEditedProjectId: () => initialState,
  },
})

export const { setEditedProjectId, clearEditedProjectId } = editShouldCostSlice.actions

export default editShouldCostSlice.reducer
