import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getAttachment } from "./rfi-rfp.async";

const initialState = {
    attachmentLoader: false,
    attachmentError: "",
  attachmentData: [],
};

export const attachmentSlice = createSlice({
  name: "attachment",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getAttachment.pending), (state) => {
      state.attachmentLoader = true;
    });
    builder.addMatcher(isAnyOf(getAttachment.fulfilled), (state, action) => {
      state.attachmentLoader = false;
      state.attachmentData = action.payload;
      state.attachmentError = "";
    });
    builder.addMatcher(isAnyOf(getAttachment.rejected), (state, action) => {
      state.attachmentLoader = false;
      state.attachmentError = action.payload;
    });
  },
  reducers: {
    emptyattachmentSlice: (state) => {
      state.attachmentLoader = false;
      state.attachmentError = "";
      state.attachmentData = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default attachmentSlice.reducer;
