import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getTemplateLibAsync } from "src/redux/async/libraries/template";

const initialState = {
    templateLibLoader: false,
    templateLibError: "",
    templateLib_state: [],
  };
  
  export const templateLibrariesSlice = createSlice({
    name: "TemplateLibrariesData",
    initialState,
    extraReducers: (builder) => {
      builder.addMatcher(isAnyOf(getTemplateLibAsync.pending), (state) => {
        state.templateLibLoader = true;
      });
      builder.addMatcher(isAnyOf(getTemplateLibAsync.fulfilled), (state, action) => {
        state.templateLibLoader = false;
        state.templateLib_state = action.payload;
        state.templateLibError = "";
      });
      builder.addMatcher(isAnyOf(getTemplateLibAsync.rejected), (state, action) => {
        state.templateLibLoader = false;
        state.templateLibError = action.payload;
      });
    },
  });
  
  export default templateLibrariesSlice.reducer;