import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import '@coreui/coreui/dist/css/coreui.min.css'
import store, { persistor } from './redux/store'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { PersistGate } from 'redux-persist/integration/react'
import config from './config/config'

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: config.AUTH_APP_ID,
    authority: config.AUTHORITY,
    redirectUri: config.AUTH_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </MsalProvider>,
)
