import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getKpisAsync } from 'src/services/shouldCostAIBased.services'

const initialState = {
  getKpisLoader: false,
  getKpisData: [],
  getKpisError: '',
}

export const getKpisSlice = createSlice({
  name: 'get-kpi',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getKpisAsync.pending), (state) => {
      state.getKpisLoader = true
    })
    builder.addMatcher(isAnyOf(getKpisAsync.fulfilled), (state, action) => {
      state.getKpisLoader = false
      state.getKpisData = action.payload.data
    })
    builder.addMatcher(isAnyOf(getKpisAsync.rejected), (state, action) => {
      state.getKpisLoader = false
      state.getKpisError = action.payload.message
    })
  },
})

export default getKpisSlice.reducer
