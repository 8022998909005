import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getSupplierCount } from './rfi-rfp.async'

const initialState = {
  supplierCountLoader: false,
  supplierCountData: [],
  supplierCountError: '',
}

export const supplierCountRegion = createSlice({
  name: 'Supplier-Count-Region',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getSupplierCount.pending), (state) => {
      state.supplierCountLoader = true
    })
    builder.addMatcher(isAnyOf(getSupplierCount.fulfilled), (state, action) => {
      state.supplierCountLoader = false
      state.supplierCountData = action.payload
    })
    builder.addMatcher(isAnyOf(getSupplierCount.rejected), (state, action) => {
      state.supplierCountLoader = false
      state.supplierCountError = action.payload.message
    })
  },
})

export default supplierCountRegion.reducer