import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getCountriesDataAsync } from 'src/redux/async/libraries/machine'

const initialState = {
  countriesLoader: false,
  countriesError: '',
  countries_state: [],
}

export const countriesDataSlice = createSlice({
    name:"countriesData",
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(getCountriesDataAsync.pending), (state) => {
          state.countriesLoader = true;
        });
        builder.addMatcher(isAnyOf(getCountriesDataAsync.fulfilled), (state, action) => {
          state.countriesLoader = false;
          state.countries_state = action.payload;
          state.countriesError = "";
        });
        builder.addMatcher(isAnyOf(getCountriesDataAsync.rejected), (state, action) => {
          state.countriesLoader = false;
          state.countriesError = action.payload;
        });
      },
})

export default countriesDataSlice.reducer;