import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getSupplierInformationAP } from './rfi-rfp.async'

const initialState = {
  supplierInfomationapLoader: false,
  supplierInfomationapData: [],
  supplierInfomationapError: '',
}

export const supplierInfomationAPSlice = createSlice({
  name: 'Supplier-Information',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getSupplierInformationAP.pending), (state) => {
      state.supplierInfomationapLoader = true
    })
    builder.addMatcher(isAnyOf(getSupplierInformationAP.fulfilled), (state, action) => {
      state.supplierInfomationapLoader = false
      state.supplierInfomationapData = action.payload
    })
    builder.addMatcher(isAnyOf(getSupplierInformationAP.rejected), (state, action) => {
      state.supplierInfomationapLoader = false
      state.supplierInfomationapError = action.payload.message
    })
  },
})

export default supplierInfomationAPSlice.reducer
