import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { SetProjectDetails } from './rfi-rfp.async'

const initialState = {
  ProjectID: null,
}

export const ProjectNameSlice = createSlice({
  name: 'Project Name',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(SetProjectDetails.fulfilled), (state, action) => {
      state.ProjectID = action.payload.project_id
    })
  },
})

export default ProjectNameSlice.reducer