import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  getSectionDetailsAsync,
  updateProjectData,
} from 'src/redux/async/ice/buildshouldcostdetails/bomSpecTabAsync';

const initialState = {
  specification: [],
  subsection: [],
  subsectionFinalFormula: {},
  sectionDetailsLoader: false,
  sectionDetailsError: '',
  projectUpdateLeader: false,
};

export const billOfMaterialSpecifications = createSlice({
  name: 'sectionDetails',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getSectionDetailsAsync.pending), (state) => {
      state.sectionDetailsLoader = true;
    });

    builder.addMatcher(isAnyOf(getSectionDetailsAsync.fulfilled), (state, action) => {
      state.sectionDetailsLoader = false;
      state.specification = action.payload.data.specification;
      state.subsection = action.payload.data.subsection;
      state.subsectionFinalFormula = { ...action.payload.data.subsectionFinalFormula };
    });

    builder.addMatcher(isAnyOf(getSectionDetailsAsync.rejected), (state, action) => {
      state.sectionDetailsLoader = false;
      state.sectionDetailsError = action.payload.message;
    });
    builder.addMatcher(isAnyOf(updateProjectData.pending), (state) => {
      state.projectUpdateLeader = true;
    });

    builder.addMatcher(isAnyOf(updateProjectData.fulfilled), (state, action) => {
      state.projectUpdateLeader = false;
    });

    builder.addMatcher(isAnyOf(updateProjectData.rejected), (state, action) => {
      state.projectUpdateLeader = false;
    });
  },
  reducers: {
    emptySpecificationDetails: () => initialState,
  },
});
export const { emptySpecificationDetails } = billOfMaterialSpecifications.actions;
export default billOfMaterialSpecifications.reducer;
