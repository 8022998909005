import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getStatusLine } from "./rfi-rfp.async";

const initialState = {
  statusLineLoader: false,
  statusLineError: "",
  statusLine_state: [],
};

export const statusLineSlice = createSlice({
  name: "statusLine",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getStatusLine.pending), (state) => {
      state.statusLineLoader = true;
    });
    builder.addMatcher(isAnyOf(getStatusLine.fulfilled), (state, action) => {
      state.statusLineLoader = false;
      state.statusLine_state = action.payload;
      state.statusLineError = "";
    });
    builder.addMatcher(isAnyOf(getStatusLine.rejected), (state, action) => {
      state.statusLineLoader = false;
      state.statusLineError = action.payload;
    });
  },
  reducers: {
    emptystatusLineSlice: (state) => {
      state.statusLineLoader = false;
      state.statusLineError = "";
      state.statusLine_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default statusLineSlice.reducer;
