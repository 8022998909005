import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getTopCommodities } from 'src/redux/async/ice/overviewasync'

const initialState = {
  sortedBarChartData: [],
  barChartLoader: false,
  barChartDataError: '',
}

export const barChartSlice = createSlice({
  name: 'barChartSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getTopCommodities.pending), (state) => {
      state.barChartLoader = true
    })
    builder.addMatcher(isAnyOf(getTopCommodities.fulfilled), (state, action) => {
      state.barChartLoader = false
      state.sortedBarChartData = action.payload
      state.barChartDataError = ''
    })

    builder.addMatcher(isAnyOf(getTopCommodities.rejected), (state, action) => {
      state.barChartLoader = false
      state.barChartDataError = action.payload
    })
  },
})

export default barChartSlice.reducer
