import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from '../../AxiosClient'

export const uploadProductsDataFromExcel = createAsyncThunk(
  'datamanagement/uploadexcel',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/data-mgmt/upload/add-supplier`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const SetProjectDetails = createAsyncThunk(
  'SetProjectDetails',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return payload
  },
)

export const receiveMail = createAsyncThunk(
  'rfi-rfp/receive-mail',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/mail/`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const sendMail = createAsyncThunk(
  'rfi-rfp/send-mail',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/mail/send-mail`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const replyMail = createAsyncThunk(
  'rfi-rfp/reply-mail',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/mail/reply-to-mail`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getStatus = createAsyncThunk(
  'rfi-rfp/send-mail/get-status',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', `/v1/rfi-rfp/send-mail/get-status`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getStatusLine = createAsyncThunk(
  'rfi-rfp/send-mail/get-status-line',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/send-mail/get-status-line`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const updateStatusMail = createAsyncThunk(
  'rfi-rfp/mail/update-status-mail',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/mail/update-status`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
export const getInbox = createAsyncThunk(
  'rfi-rfp/inbox',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/mail/receive-mail`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getProjectwiseInbox = createAsyncThunk(
  'rfi-rfp/projectwise-inbox',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfx-mgmt/monitor-responses/get-all-emails`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const uploadFile = createAsyncThunk(
  'rfi-rfp/upload-file',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/upload-file`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getKipsMonitorResponses = createAsyncThunk(
  'rfi-rfp/monitor-response/get-kpis',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/monitor-response/get-kpis`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getCurrentStatus = createAsyncThunk(
  'rfi-rfp/monitor-response/get-current-status',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/send-mail/get-current-status-line`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getPieChartMonitorResponses = createAsyncThunk(
  'rfi-rfp/monitor-response/get-pie-chart',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/monitor-response/get-pie-chart`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getMonitorTable = createAsyncThunk(
  'rfi-rfp/monitor-response/get-table-for-email',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/monitor-response/get-table-for-emails`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
export const getprojectDetails = createAsyncThunk(
  'rfi-rfp/project-details',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/project-details`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getprojectFilterSendAMail = createAsyncThunk(
  'rfi-rfp/send-a-mail/project-filter',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `/v1/rfi-rfp/create-project/get-filter-data-send-mail`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)
export const setprojectDetails = createAsyncThunk(
  'rfi-rfp/set-project-details',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/set-project-details`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
export const addSupplierstoProject = createAsyncThunk(
  'rfi-rfp/set-project-supplier-details',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/set-project-supplier-details`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
export const getProjectDetailsWithSuppliers = createAsyncThunk(
  'rfi-rfp/get-project-details',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/get-project-supplier-details`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const deleteSuppliersForProject = createAsyncThunk(
  'rfi-rfp/delete-supplier',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/delete-project-supplier-details`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const updateProjectStatus = createAsyncThunk(
  'rfi-rfp/create-project/update-project-status',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/update-project-status`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getFiltersAsync = createAsyncThunk(
  'rfi-rfp/create-project/get-filters',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', '/v1/rfi-rfp/create-project/get-filter-data', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getTemplates = createAsyncThunk(
  'rfi-rfp/get-templates',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', `/v1/rfi-rfp/get-templates`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getAllTemplates = createAsyncThunk(
  'rfi-rfp/get-all-templates',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', `/v1/rfi-rfp/get-all-templates`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const deleteTemplate = createAsyncThunk(
  'rfi-rfp/delete-template',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/delete-template`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
//Supply market anlysis l1,l2,l3
export const getFiltersSearchSuppliers = createAsyncThunk(
  'rfi-rfp/search-suppliers/get-filters',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', '/v1/supplier-market-analysis/get-filter', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getSupplierInformation = createAsyncThunk(
  'rfi-rfp/supplier-information',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `/v1/rfi-rfp/search-suppliers/get-suppliers-information`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

export const getSupplierInformationAsync = createAsyncThunk(
  'rfi-rfp/supplier-information',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `/v2/supplier-market-analysis/get-all-supplier-details`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

export const getSupplierInformationFromAI = createAsyncThunk(
  'rfi-rfp/supplier-information-ai',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `/v1/supplier-market-analysis/get-all-supplier-details`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

export const getSupplierInformationFromAIV2 = createAsyncThunk(
  'rfi-rfp/supplier-information-ai',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `/v2/supplier-market-analysis/get-suppliers-name-openai`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

export const getSupplierInformationFromNameAIV2 = createAsyncThunk(
  'rfi-rfp/supplier-information-ai',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `v2/supplier-market-analysis/get-suppliers-details-from-name-openai`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

export const getRFISuppliersAnalysis = createAsyncThunk(
  'rfi-rfp/selected-suppliers-rfi-analysis',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `/v1/rfi-rfp/monitor-response/selected-suppliers-rfi-analysis`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

export const getSupplierInformationFromOpenAI = createAsyncThunk(
  'rfi-rfp/supplier-information-openai',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `/v1/supplier-market-analysis/get-ai-supplier-details`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

export const getSupplierContactInformationFromBing = createAsyncThunk(
  'rfi-rfp/supplier-contact-information-ai',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `/v1/supplier-market-analysis/get-supplier-contact-details`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

export const saveSupplierInformationFromAI = createAsyncThunk(
  'rfi-rfp/save-supplier-information-ai',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/openAI/saveSupplier/save-supplier-information`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getSupplierInformationAP = createAsyncThunk(
  'rfi-rfp/supplier-information-ap',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `/v1/rfi-rfp/search-suppliers/get-suppliers-information-ap`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

export const getSupplierCount = createAsyncThunk(
  'rfi-rfp/get-supplier-count',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/search-suppliers/get-supplier-count`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getAttachment = createAsyncThunk(
  'rfi-rfp/get-attachment',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/mail/get-attachment`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getMailThreads = createAsyncThunk(
  'rfi-rfp/get-thread-mails',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/mail/get-thread-mails`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getIsReplied = createAsyncThunk(
  'rfi-rfp/get-isreplied',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/send-mail/get-isreplied`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const updateIsRead = createAsyncThunk(
  'rfi-rfp/get-isreplied',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/mail/update-is-read`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

///searchBar
//getSupplierCountByCategory
export const getSupplierCountByCategory = createAsyncThunk(
  //getSupplierCountByCategory
  'vcn/getSupplierCountByCategory',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `/v1/rfi-rfp/search-suppliers/get-supplier-catogarywise`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

export const exportToCSVPDF = createAsyncThunk(
  'rfi-rfp/export-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/export-suppliers/export-data`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const sendamail = createAsyncThunk(
  'send-mail-page',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/mail/sendmail`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getMailInbox = createAsyncThunk(
  'send-mail-inbox',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/rfi-rfp/mail/receivemail`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getConsolidatedFilesTable = createAsyncThunk(
  'monitor-responses/generate-consolidated-files-table',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `/v1/rfi-rfp/monitor-response/get-rfi-analysis-table`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

export const getSupplierRFxAnalysis = createAsyncThunk(
  'monitor-responses/supplier-rfx-analysis',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `/v1/rfi-rfp/monitor-response/selected-suppliers-rfi-analysis`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

//uploadTemplateOnCreate
export const uploadSupplierFile = createAsyncThunk(
  'rfi-rfp/uploadSupplierFile',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'POST',
      `/v1/rfi-rfp/upload-suppliers/upload_project_supplier_template`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)
