import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
    getProdcutCategoryGroupAsync,
    getProductCategoriesAsync,
    getProductSubCategoriesAsync,
    getProductRowsAsync,
    getTemplatesLibraryAsync,
    deleteTemplateAsync,
    getElementsAsync,
    getConverionCountriesAsync,
    getUseTemplateAsync,
    markTemplateAsync,
    uploadTemplateAsync,
    uploadSimplifiedTemplateAsync,
    uploadTemplateDataAsync,
    uploadTemplateStatusAsync,
    deleteTemplateDataAsync,
    deleteTemplateDataSimAsync,
    simplifiedHandlerAsync,
    readFileHandlerAsync,
    getMassTemplateCalculationAsync,
    getSimplifiedTemplateCalculationAsync,

    getExistingTemplatesAsync
} from './massUploadAsync'

const initialState = {
    massUploadLoader: false,
    ///////////////////////
    getProdcutCategoryGroup: [],
    getProductCategories: [],
    getProductSubCategories: [],
    getProductRows: [],
    getTemplatesLibrary: [],
    deleteTemplate: [],
    getElements: [],
    getConverionCountries: [],
    getUseTemplate: [],
    markTemplate: [],
    uploadTemplate: [],
    uploadSimplifiedTemplate: [],
    uploadTemplateData: {},
    uploadTemplateStatus: [],
    deleteTemplateData: {},
    deleteTemplateDataSim: [],
    simplifiedHandler: [],
    readFileHandler: [],
    getMassTemplateCalculation: [],

    getExistingTemplates: [],
    ///////////////////////
    massUploadError: '',
}

export const MassUploadSlice = createSlice({
    name: 'MassUpload',
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(getProdcutCategoryGroupAsync.pending, getProductCategoriesAsync.pending, getProductSubCategoriesAsync.pending, getProductRowsAsync.pending, getTemplatesLibraryAsync.pending, deleteTemplateAsync.pending, getElementsAsync.pending, getConverionCountriesAsync.pending, getUseTemplateAsync.pending, markTemplateAsync.pending, uploadTemplateAsync.pending, uploadSimplifiedTemplateAsync.pending, uploadTemplateDataAsync.pending, uploadTemplateStatusAsync.pending, deleteTemplateDataAsync.pending, deleteTemplateDataSimAsync.pending, simplifiedHandlerAsync.pending, readFileHandlerAsync.pending, getExistingTemplatesAsync.pending), (state) => {
            state.massUploadLoader = true
        })
        builder.addMatcher(isAnyOf(getProdcutCategoryGroupAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.getProdcutCategoryGroup = action.payload
        })
        builder.addMatcher(isAnyOf(getProductCategoriesAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.getProductCategories = action.payload
        })
        builder.addMatcher(isAnyOf(getProductSubCategoriesAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.getProductSubCategories = action.payload
        })
        builder.addMatcher(isAnyOf(getProductRowsAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.getProductRows = action.payload
        })
        builder.addMatcher(isAnyOf(getTemplatesLibraryAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.getTemplatesLibrary = action.payload
        })
        builder.addMatcher(isAnyOf(deleteTemplateAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.deleteTemplate = action.payload
        })
        builder.addMatcher(isAnyOf(getElementsAsync.fulfilled), (state, action) => {
            state.getElements = action.payload
            state.massUploadLoader = false
        })
        builder.addMatcher(isAnyOf(getConverionCountriesAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.getConverionCountries = action.payload
        })
        builder.addMatcher(isAnyOf(getUseTemplateAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.getUseTemplate = action.payload
        })
        builder.addMatcher(isAnyOf(markTemplateAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.markTemplate = action.payload
        })
        builder.addMatcher(isAnyOf(uploadTemplateAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.uploadTemplate = action.payload
        })
        builder.addMatcher(isAnyOf(uploadSimplifiedTemplateAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.uploadSimplifiedTemplate = action.payload
        })
        builder.addMatcher(isAnyOf(uploadTemplateDataAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.uploadTemplateData = action.payload
        })
        builder.addMatcher(isAnyOf(uploadTemplateStatusAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.uploadTemplateStatus = action.payload
        })
        builder.addMatcher(isAnyOf(deleteTemplateDataAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.deleteTemplateData = action.payload
        })
        builder.addMatcher(isAnyOf(deleteTemplateDataSimAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.deleteTemplateDataSim = action.payload
        })
        builder.addMatcher(isAnyOf(simplifiedHandlerAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.simplifiedHandler = action.payload
        })
        builder.addMatcher(isAnyOf(readFileHandlerAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.readFileHandler = action.payload
        })
        builder.addMatcher(isAnyOf(getMassTemplateCalculationAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.getMassTemplateCalculation = action.payload
        })
        builder.addMatcher(isAnyOf(getSimplifiedTemplateCalculationAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.getMassTemplateCalculation = action.payload
        })
        builder.addMatcher(isAnyOf(getExistingTemplatesAsync.fulfilled), (state, action) => {
            state.massUploadLoader = false
            state.getExistingTemplates = action.payload
        })
        builder.addMatcher(isAnyOf(getProdcutCategoryGroupAsync.rejected, getProductCategoriesAsync.rejected, getProductSubCategoriesAsync.rejected, getProductRowsAsync.rejected, getTemplatesLibraryAsync.rejected, deleteTemplateAsync.rejected, getElementsAsync.rejected, getConverionCountriesAsync.rejected, getUseTemplateAsync.rejected, markTemplateAsync.rejected, uploadTemplateAsync.rejected, uploadSimplifiedTemplateAsync.rejected, uploadTemplateDataAsync.rejected, uploadTemplateStatusAsync.rejected, deleteTemplateDataAsync.rejected, deleteTemplateDataSimAsync.rejected, simplifiedHandlerAsync.rejected, readFileHandlerAsync.rejected, getMassTemplateCalculationAsync.rejected,getSimplifiedTemplateCalculationAsync.rejected, getExistingTemplatesAsync.rejected), (state, action) => {
            state.massUploadLoader = false
            state.massUploadError = action.payload.message
        })
    },
})

export default MassUploadSlice.reducer