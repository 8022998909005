import { createSlice, isAnyOf } from '@reduxjs/toolkit'

const initialState = {
    mailSetstate:false
}

export const mailSetSlice = createSlice({
  name: 'mailSet',
  initialState,
  reducers:{
    setMail(state,action){
        state.mailSetstate=action.payload
    }
  }
})
export const { setMail} = mailSetSlice.actions

export default mailSetSlice.reducer
