import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getMailThreads } from "./rfi-rfp.async";

const initialState = {
    threadMailLoader: false,
    threadMailError: "",
  threadMail_state: [],
};

export const threadMailSlice = createSlice({
  name: "threadMail",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getMailThreads.pending), (state) => {
      state.threadMailLoader = true;
    });
    builder.addMatcher(isAnyOf(getMailThreads.fulfilled), (state, action) => {
      state.threadMailLoader = false;
      state.threadMail_state = action.payload;
      state.threadMailError = "";
    });
    builder.addMatcher(isAnyOf(getMailThreads.rejected), (state, action) => {
      state.threadMailLoader = false;
      state.threadMailError = action.payload;
    });
  },
  reducers: {
    emptythreadMailSlice: (state) => {
      state.threadMailLoader = false;
      state.threadMailError = "";
      state.threadMail_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default threadMailSlice.reducer;
