import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getSupplierCountByCategory } from './rfi-rfp.async'

const initialState = {
  supplierCountByCategoryLoader: false,
  supplierCountByCategoryData: [],
  supplierCountByCategoryError: '',
}

//SupplierCountByCategory
export const supplierCountByCategory = createSlice({
  name: 'vcn-exposure/shouldCost',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getSupplierCountByCategory.pending), (state) => {
      state.supplierCountByCategoryLoader = true
    })
    builder.addMatcher(isAnyOf(getSupplierCountByCategory.fulfilled), (state, action) => {
      state.supplierCountByCategoryLoader = false
      state.supplierCountByCategoryData = action.payload
    })
    builder.addMatcher(isAnyOf(getSupplierCountByCategory.rejected), (state, action) => {
      state.supplierCountByCategoryLoader = false
      state.supplierCountByCategoryError = action.payload.message
    })
  },
  reducers: {
    resetSupplierCountByCategoryDetails: () => initialState,
  },
})
export const { resetSupplierCountByCategoryDetails } = supplierCountByCategory.actions
export default supplierCountByCategory.reducer
