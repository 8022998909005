import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getCompetitorTableData } from 'src/redux/async'

const initialState = {
  caTableLoader: false,
  caTableError: '',
  caTableData: [],
}

export const competitorTableSlice = createSlice({
  name: 'CompetitorAnalysisTable',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getCompetitorTableData.pending), (state) => {
      state.caTableLoader = true
    })
    builder.addMatcher(isAnyOf(getCompetitorTableData.fulfilled), (state, action) => {
      state.caTableLoader = false
      state.caTableData = action.payload
      state.caTableError = ''
    })
    builder.addMatcher(isAnyOf(getCompetitorTableData.rejected), (state, action) => {
      state.caTableLoader = false
      state.caTableError = action.payload
    })
  },
})

// export const { AddSuppliersMap } = suppliersbycountriesSlice.actions;

export default competitorTableSlice.reducer
