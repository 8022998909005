import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getPOTrendsDataAsync } from 'src/services/shouldCostAIBased.services'

const initialState = {
  getpoTrendsLoader: false,
  getpoTrendsData: [],
  getpoTrendsError: '',
}

export const getpoTrendsDataSlice = createSlice({
  name: 'get-po-trends-data',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getPOTrendsDataAsync.pending), (state) => {
      state.getpoTrendsLoader = true
    })
    builder.addMatcher(isAnyOf(getPOTrendsDataAsync.fulfilled), (state, action) => {
      state.getpoTrendsLoader = false
      state.getpoTrendsData = action.payload.data
    })
    builder.addMatcher(isAnyOf(getPOTrendsDataAsync.rejected), (state, action) => {
      state.getpoTrendsLoader = false
      state.getpoTrendsError = action?.payload?.message
    })
  },
  reducers: {
    resetTrendsData: () => initialState,
  },
})

export const { resetTrendsData } = getpoTrendsDataSlice.actions

export default getpoTrendsDataSlice.reducer
