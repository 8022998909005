
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getMonitorTable } from "./rfi-rfp.async";

const initialState = {
  emailTablesLoader: false,
  emailTablesError: "",
  emailTables_state: [],
};

export const emailTablesSlice = createSlice({
  name: "emailTables",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getMonitorTable.pending), (state) => {
      state.emailTablesLoader = true;
    });
    builder.addMatcher(isAnyOf(getMonitorTable.fulfilled), (state, action) => {
      state.emailTablesLoader = false;
      state.emailTables_state = action.payload;
      state.emailTablesError = "";
    });
    builder.addMatcher(isAnyOf(getMonitorTable.rejected), (state, action) => {
      state.emailTablesLoader = false;
      state.emailTablesError = action.payload;
    });
  },
  reducers: {
    emptyemailTablesSlice: (state) => {
      state.emailTablesLoader = false;
      state.emailTablesError = "";
      state.emailTables_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default emailTablesSlice.reducer;
