import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getIcrCommodityTableDataAsync, getIcrCommodityFormTableDataAsync, createCommoditityAsync } from 'src/redux/async'

const initialState = {
    CommodityLoader: false,
    getIcrCommodityTableData: [],
    getIcrCommodityFormTableData: [],
    createCommoditity: {},
    CommodityError: '',
}

export const CommoditySlice = createSlice({
    name: 'Commodity Data',
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            getIcrCommodityTableDataAsync.pending,
            getIcrCommodityFormTableDataAsync.pending, 
            createCommoditityAsync.pending,
            ), (state) => {
                state.CommodityLoader = true
        })
        builder.addMatcher(isAnyOf(getIcrCommodityTableDataAsync.fulfilled), (state, action) => {
            state.CommodityLoader = false
            state.getIcrCommodityTableData = action.payload
        })
        builder.addMatcher(isAnyOf(getIcrCommodityFormTableDataAsync.fulfilled), (state, action) => {
            state.CommodityLoader = false
            state.getIcrCommodityFormTableData = action.payload
        })
        builder.addMatcher(isAnyOf(createCommoditityAsync.fulfilled), (state, action) => {
            state.CommodityLoader = false
            state.createCommoditity = action.payload
        })
        builder.addMatcher(isAnyOf(
            getIcrCommodityTableDataAsync.rejected,
            getIcrCommodityFormTableDataAsync.rejected,
            createCommoditityAsync.rejected,
        ), (state, action) => {
            state.CommodityLoader = false
            state.CommodityError = 'error'
        })
    },
})

export default CommoditySlice.reducer;
