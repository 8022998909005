import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getSummaryTableData, updateStatus } from '../../async/ice/iceasync';

const initialState = {
  summaryTableLoader: false,
  summaryTableError: '',
  updateStatusLoader: false,
  updateStatusData: [],
  summaryTable_state: [],
};

export const summaryTableSlice = createSlice({
  name: 'summaryTable',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getSummaryTableData.pending, updateStatus.pending), (state) => {
      state.summaryTableLoader = true;
      // state.updateStatusLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getSummaryTableData.fulfilled, updateStatus.fulfilled),
      (state, action) => {
        state.summaryTableLoader = false;
        state.summaryTable_state = action.payload;
        state.summaryTableError = '';
      },
    );

    builder.addMatcher(
      isAnyOf(getSummaryTableData.rejected, updateStatus.rejected),
      (state, action) => {
        state.summaryTableLoader = false;
        state.summaryTableError = action.payload;
      },
    );
  },
  reducers: {
    emptysummaryTableSlice: (state) => {
      state.summaryTableLoader = false;
      state.summaryTableError = '';
      state.summaryTable_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default summaryTableSlice.reducer;
