import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const getTemplateLibAsync = createAsyncThunk(
    'libraries/template',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('GET', '/v1/template/master', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )