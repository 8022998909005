import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getMachineLibAsync, deleteMachineAsync } from 'src/redux/async/libraries/machine'

const initialState = {
  machineLibLoader: false,
  machineLibError: '',
  machineLib_state: [],
  machineDeleteLoader: false,
  machineDeleteData: [],
}

export const machineLibrariesSlice = createSlice({
  name: 'MachineLibrariesData',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getMachineLibAsync.pending), (state) => {
      state.machineLibLoader = true
    })
    builder.addMatcher(isAnyOf(deleteMachineAsync.pending), (state) => {
      state.machineDeleteLoader = true
    })
    builder.addMatcher(isAnyOf(getMachineLibAsync.fulfilled), (state, action) => {
      state.machineLibLoader = false
      state.machineLib_state = action.payload
      state.machineLibError = ''
    })
    builder.addMatcher(isAnyOf(deleteMachineAsync.fulfilled), (state, action) => {
      state.machineDeleteLoader = false
      state.machineDeleteData = action.payload
    })
    builder.addMatcher(isAnyOf(getMachineLibAsync.rejected), (state, action) => {
      state.machineLibLoader = false
      state.machineLibError = action.payload
    })

    builder.addMatcher(isAnyOf(deleteMachineAsync.rejected), (state, action) => {
      state.machineDeleteLoader = false
      state.machineDeleteData = action.payload
    })
  },
})

export default machineLibrariesSlice.reducer
