import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const updateProjectDataAsync = createAsyncThunk(
    'otherCost/updateProjectData',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', '/v1/projectData', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )