import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getFormulaItems } from 'src/redux/async/ice/buildshouldcostdetails/buildshouldcostdetailsasync';

const initialState = {
  formulaItemsData: [],
  formulaItemsLoader: false,
  formulaItemsDataError: '',
};

export const formulaItemsSlice = createSlice({
  name: 'formulaItemsSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getFormulaItems.pending), (state) => {
      state.formulaItemsLoader = true;
    });
    builder.addMatcher(isAnyOf(getFormulaItems.fulfilled), (state, action) => {
      state.formulaItemsLoader = false
      state.formulaItemsData = action.payload
    })

    builder.addMatcher(isAnyOf(getFormulaItems.rejected), (state, action) => {
      state.formulaItemsLoader = false;
      state.formulaItemsDataError = action.payload;
    });
  },
});

export default formulaItemsSlice.reducer;
