import { createSlice, isAnyOf } from '@reduxjs/toolkit'

const initialState = {
    mailBodystate:[]
}

export const mailBodySlice = createSlice({
  name: 'mailBody',
  initialState,
  reducers:{
    setmailBody(state,action){
        state.mailBodystate=action.payload
    }
  }
})
export const { setmailBody} = mailBodySlice.actions

export default mailBodySlice.reducer
