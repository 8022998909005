import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { validateFileUploadsync, fileUploadAsync } from 'src/services/shouldCostAIBased.services'

const initialState = {
  upload: 'sku', 
  file: {},
  fileError: [],
  data: [],
  loader: false,
  apiError: '',
  tab: 1, //1 for upload and 2 for preview
  modalVisible: false,
  toast: false,
  isValidationSuccessful: '',
  isUploadSuccessful: false,
}

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setUpload: (state, action) => {
      state.upload = action.payload
    },
    setFile: (state, action) => {
      state.file = action.payload
      state.fileError = []
      state.isValidationSuccessful = ''
      state.data = []
      state.apiError = ''
    },
    setFileError: (state, action) => {
      state.fileError = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setTab: (state, action) => {
      state.tab = action.payload
    },
    setModalVisible: (state, action) => {
      state.modalVisible = action.payload
    },
    setToast: (state, action) => {
      state.toast = action.payload
    },
    setIsValidationSuccessful: (state, action) => {
      state.isValidationSuccessful = action.payload
    },
    setIsUploadSuccessful: (state, action) => {
      state.isUploadSuccessful = action.payload
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(validateFileUploadsync.pending), (state) => {
      state.loader = true
      state.isValidationSuccessful = ''
    })
    builder.addMatcher(isAnyOf(validateFileUploadsync.fulfilled), (state, action) => {
      state.loader = false
      state.data = action.payload.data
      if (action.payload.data.preview_data) {
        state.isValidationSuccessful = true
        state.tab = 2
      } else {
        state.isValidationSuccessful = false
      }
    })
    builder.addMatcher(isAnyOf(validateFileUploadsync.rejected), (state, action) => {
      state.loader = false
      state.apiError = action.payload.message
    })

    //file uploading
    builder.addMatcher(isAnyOf(fileUploadAsync.pending), (state) => {
      state.loader = true
    })
    builder.addMatcher(isAnyOf(fileUploadAsync.fulfilled), (state, action) => {
      state.loader = false
      state.isUploadSuccessful = true
    })
    builder.addMatcher(isAnyOf(fileUploadAsync.rejected), (state, action) => {
      state.loader = false
      state.apiError = action.payload.message
      state.isUploadSuccessful = false
    })
  },
})
export const {
  setUpload,
  setFile,
  setFileError,
  setData,
  setLoader,
  setTab,
  setModalVisible,
  setToast,
  setIsValidationSuccessful,
  setIsUploadSuccessful,
  reset,
} = uploadSlice.actions
export default uploadSlice.reducer
