import { createSlice, isAnyOf } from '@reduxjs/toolkit'

const initialState = {
  shipperFilterState: {},
}

export const shipperFiltersSlice = createSlice({
  name: 'shipperFilters',
  initialState,
  reducers: {
    setShipperFilterState(state, action) {
      state.shipperFilterState = action.payload
    },
    clearShipperFilterState(state) {
      state.shipperFilterState = {}
    },
  },
})
export const {
  setShipperFilterState,
  clearShipperFilterState,
} = shipperFiltersSlice.actions

export default shipperFiltersSlice.reducer
