import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosClient } from '../../AxiosClient';

export const getSummaryChartData = createAsyncThunk(
  'shouldcost/summary-chart-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/chartCostSummaryDetails`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  },
);
export const getSummaryTableData = createAsyncThunk(
  'shouldcost/summary-table-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/projectSummaryTable`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  },
);

export const updateProjectData = createAsyncThunk(
  'shouldcost/updateProjectData',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `v1/updateProjectData`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  },
);

export const updateStatus = createAsyncThunk(
  'shouldcost/updateStatus',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `v1/project/updateStatus`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  },
);


export const deleteModifyShouldCostProject = createAsyncThunk(
  'shouldcost/modifyShouldcost/deleteProject',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v2/deleteProject`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  },
);

export const exportShouldCostProject = createAsyncThunk(
  'shouldcost/export-project',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/exportMultipleShouldCostProjects`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  },
);