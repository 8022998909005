import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getTopRegionsbyCompany } from "src/redux/async";

const initialState = {
  cafiltersLoader: false,
  cafiltersError: "",
  cafiltersData: [],
};

export const topRegionsbyCompany = createSlice({
  name: "topRegionsbyCompany",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getTopRegionsbyCompany.pending), (state) => {
      state.cafiltersLoader = true;
    });
    builder.addMatcher(isAnyOf(getTopRegionsbyCompany.fulfilled), (state, action) => {
      state.cafiltersLoader = false;
      state.cafiltersData = action.payload;
      state.cafiltersError = "";
    });
    builder.addMatcher(isAnyOf(getTopRegionsbyCompany.rejected), (state, action) => {
      state.cafiltersLoader = false;
      state.cafiltersError = action.payload;
    });
  },
});

// export const { AddSuppliersMap } = suppliersbycountriesSlice.actions;

export default topRegionsbyCompany.reducer;
