import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getprojectFilterSendAMail } from "./rfi-rfp.async";

const initialState = {
  projectFilterLoader: false,
  projectFilterError: "",
  projectFilter_state: [],
};

export const projectfiltermailSlice = createSlice({
  name: "projectFilter",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getprojectFilterSendAMail.pending), (state) => {
      state.projectFilterLoader = true;
    });
    builder.addMatcher(isAnyOf(getprojectFilterSendAMail.fulfilled), (state, action) => {
      state.projectFilterLoader = false;
      state.projectFilter_state = action.payload;
      state.projectFilterError = "";
    });
    builder.addMatcher(isAnyOf(getprojectFilterSendAMail.rejected), (state, action) => {
      state.projectFilterLoader = false;
      state.projectFilterError = action.payload;
    });
  },
  reducers: {
    emptyprojectfiltermailSlice: (state) => {
      state.projectFilterLoader = false;
      state.projectFilterError = "";
      state.projectFilter_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default projectfiltermailSlice.reducer;
