import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const getCommodityLibAsync = createAsyncThunk(
  'libraries/commodity',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', '/v1/commodities', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
