import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getMailInbox } from "./rfi-rfp.async";

const initialState = {
    mailInboxLoader: false,
    mailInboxError: "",
  mailInbox_state: [],
};

export const mailInbox = createSlice({
  name: "inbox",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getMailInbox.pending), (state) => {
      state.mailInboxLoader = true;
    });
    builder.addMatcher(isAnyOf(getMailInbox.fulfilled), (state, action) => {
      state.mailInboxLoader = false;
      state.mailInbox_state = action.payload;
      state.mailInboxError = "";
    });
    builder.addMatcher(isAnyOf(getMailInbox.rejected), (state, action) => {
      state.mailInboxLoader = false;
      state.mailInboxError = action.payload;
    });
  },
  reducers: {
    emptymailInbox: (state) => {
      state.mailInboxLoader = false;
      state.mailInboxError = "";
      state.mailInbox_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default mailInbox.reducer;
