import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getShouldCostFilterAsync } from 'src/redux/async/vcn/vcn.async'

const initialState = {
  shouldCostFilterLoader: false,
  shouldCostFilterData: [],
  shouldCostFilterError: '',
}

export const shouldCostPoFilterSlice = createSlice({
  name: 'vcn-exposure/shouldCostfilter',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getShouldCostFilterAsync.pending), (state) => {
      state.shouldCostFilterLoader = true
    })
    builder.addMatcher(isAnyOf(getShouldCostFilterAsync.fulfilled), (state, action) => {
      state.shouldCostFilterLoader = false
      state.shouldCostFilterData = action.payload
    })
    builder.addMatcher(isAnyOf(getShouldCostFilterAsync.rejected), (state, action) => {
      state.shouldCostFilterLoader = false
      state.shouldCostFilterError = action.payload.message
    })
  },
  reducers: {
    resetshouldCostFilterDetails: () => initialState,
  },
})
export const { resetshouldCostFilterDetails } = shouldCostPoFilterSlice.actions
export default shouldCostPoFilterSlice.reducer
