import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getProductsCategoryAsync } from 'src/redux/async/vcn/vcn.async'

const initialState = {
  productCategoryLoader: false,
  productCategoryData: [],
  defaultProductCategory: [],
  productCategoryError: '',
}

export const productCategorySlice = createSlice({
  name: 'vcn-exposure/product-category',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getProductsCategoryAsync.pending), (state) => {
      state.productCategoryLoader = true
    })
    builder.addMatcher(isAnyOf(getProductsCategoryAsync.fulfilled), (state, action) => {
      state.productCategoryLoader = false
      state.productCategoryData = action.payload.data
      state.defaultProductCategory = action.payload.default
    })
    builder.addMatcher(isAnyOf(getProductsCategoryAsync.rejected), (state, action) => {
      state.productCategoryLoader = false
      state.productCategoryError = action.payload.message
    })
  },
  reducers: {
    resetCategoryDetails: () => initialState
  },
})
export const { resetCategoryDetails } = productCategorySlice.actions
export default productCategorySlice.reducer
