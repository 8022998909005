import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getSupplierInformationFromAI, getSupplierInformationFromOpenAI } from './rfi-rfp.async'

const initialState = {
  aisupplierInfomationLoader: false,
  aisupplierInformationData: [],
  aisupplierInformationError: '',
}

export const aiSupplierInformationSlice = createSlice({
  name: 'AI-Supplier-Information',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getSupplierInformationFromOpenAI.pending), (state) => {
      state.aisupplierInfomationLoader = true
    })
    builder.addMatcher(isAnyOf(getSupplierInformationFromOpenAI.fulfilled), (state, action) => {
      state.aisupplierInfomationLoader = false
      state.aisupplierInformationData = action.payload
    })
    builder.addMatcher(isAnyOf(getSupplierInformationFromOpenAI.rejected), (state, action) => {
      state.aisupplierInfomationLoader = false
      state.aisupplierInformationError = action.payload.message
    })
  },
})

export default aiSupplierInformationSlice.reducer
