export const skuManagementApiRoutes = {
  getFilters: '/v1/should-cost-analysis/get-filter-data',
  getKPIs: '/v1/should-cost-analysis/get-kpi',
  getSkuTable: '/v1/should-cost-analysis/get-project-data',
}

export const uploadApiRoutes = {
  downloadFile: 'v1/should-cost-analysis/download-template',
  uploadFile: '/v1/should-cost-analysis/upload-files',
  validateFile: '/v1/should-cost-analysis/validate-files',
}

export const poTrendsApiRoutes = {
  getTrendsData: '/v1/should-cost-analysis/get-vendor-po-data',
  getTrendsLineChartData: '/v1/should-cost-analysis/get-po-trend-data',
}

export const createShouldAPIRoutes = {
  getCreateShouldCostView: '/v1/should-cost-analysis/get-detailed-should-cost-by-product',
  getVendorList: '/v1/should-cost-analysis/get-vendor-po-data',
  createShouldCostModel: '/v1/should-cost-analysis/save-breakdown',
  getCountries: '/v1/conversion/countries',
  getShouldCostModels: '/v1/should-cost-analysis/get-models',
  getShouldCostBreakdown: '/v1/should-cost-analysis/get-should-cost-breakdown',
  editShouldCostModel: '/v1/should-cost-analysis/edit-should-cost',
}
export const commodityApiRoutes = {
  commodityData: '/v1/should-cost-analysis/get-commodity',
  commodityKPIs: '/v1/should-cost-analysis/commodity-kpi',
}

export const viewShouldCostApiRoutes = {
  trendLine: '/v1/should-cost-analysis/mini-ifa',
  checkIfaProgress: '/v2/should-cost-analysis/is-calulating',
  downloadShouldCost: '/v1/should-cost-analysis/download-should-cost',
}

export const attachModelApiRoutes = {
  getSkuData: 'v1/should-cost-analysis/get-sku-data',
  
}


