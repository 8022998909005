import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getPOTrendsLineChartDataAsync } from 'src/services/shouldCostAIBased.services'

const initialState = {
  getpoTrendsLineChartLoader: false,
  getpoTrendsLineChartData: [],
  getpoTrendsLineChartError: '',
}

export const getpoTrendsLineChartDataSlice = createSlice({
  name: 'get-po-trends-line-chart-data',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getPOTrendsLineChartDataAsync.pending), (state) => {
      state.getpoTrendsLineChartLoader = true
    })
    builder.addMatcher(isAnyOf(getPOTrendsLineChartDataAsync.fulfilled), (state, action) => {
      state.getpoTrendsLineChartLoader = false
      state.getpoTrendsLineChartData = action.payload.data
    })
    builder.addMatcher(isAnyOf(getPOTrendsLineChartDataAsync.rejected), (state, action) => {
      state.getpoTrendsLineChartLoader = false
      state.getpoTrendsLineChartError = action?.payload?.message
    })
  },
  reducers: {
    resetTrendsLineChartData: () => initialState,
  },
})

export const { resetTrendsLineChartData } = getpoTrendsLineChartDataSlice.actions

export default getpoTrendsLineChartDataSlice.reducer
