import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { sendMail } from "./rfi-rfp.async";

const initialState = {
    sendMailLoader: false,
    sendMailError: "",
  sendMail_state: [],
};

export const sendMailSlice = createSlice({
  name: "sendMail",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(sendMail.pending), (state) => {
      state.sendMailLoader = true;
    });
    builder.addMatcher(isAnyOf(sendMail.fulfilled), (state, action) => {
      state.sendMailLoader = false;
      state.sendMail_state = action.payload;
      state.sendMailError = "";
    });
    builder.addMatcher(isAnyOf(sendMail.rejected), (state, action) => {
      state.sendMailLoader = false;
      state.sendMailError = action.payload;
    });
  },
  reducers: {
    emptysendMailSlice: (state) => {
      state.sendMailLoader = false;
      state.sendMailError = "";
      state.sendMail_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default sendMailSlice.reducer;
