import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getAlternateSuppliers } from "src/redux/async";

const initialState = {
    alternateSuppliersLoader: false,
    alternateSuppliersError: "",
    alternateSuppliers_state: [],
};

export const alternateSuppliersSlice = createSlice({
  name: "getAlternateSuppliers",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getAlternateSuppliers.pending), (state) => {
      state.alternateSuppliersLoader = true;
    });
    builder.addMatcher(isAnyOf(getAlternateSuppliers.fulfilled), (state, action) => {
      state.alternateSuppliersLoader = false;
      state.alternateSuppliers_state =  action.payload.map((element)=>{if(element!=null)return element.main_supplier_id})
      state.alternateSuppliersError = "";
    });
    builder.addMatcher(isAnyOf(getAlternateSuppliers.rejected), (state, action) => {
      state.alternateSuppliersLoader = false;
      state.alternateSuppliersError = action.payload;
    });
  },
  reducers: {
    emptyalternateSuppliersSlice: (state) => {
      state.alternateSuppliersLoader = false;
      state.alternateSuppliersError = "";
      state.alternateSuppliers_state = [];
    },
  },
});

export const { emptyalternateSuppliersSlice } = alternateSuppliersSlice.actions;

export default alternateSuppliersSlice.reducer;
