import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  getMarginOverhead,
  deleteProjectDataOtherCostAsync,   updateProjectDataOtherCostAsync,
  checkDeleteProjectDataOtherCostAsync,
  sectionDetailsasync,
} from 'src/redux/async/ice/buildshouldcostdetails/othercostasync'
// import { sectionDetailsasync } from 'src/redux/async/othercost/otherCostAsync'

const initialState = {
    data: [],
  LogisticsConversionData: [],
  LogisticsCommodityData: [],
  LogisticsFinalFormula: {},
  LogisticsLoader: false,
  LogisticsError: '',
  deleteDetails: {
    deleteLoader: false,
    deleteState: [],
    deleteError: '',
  },
  updateDetails: {
    updateLoader: false,
    updateState: [],
    updateError: '',
  },
  addDetails: {
    addLoader: false,
    addState: [],
    addError: '',
  },
}

export const otherCostLogisticsSlice = createSlice({
  name: 'othercostLogistics',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(sectionDetailsasync.pending), (state) => {
      state.LogisticsLoader = true
    })

    builder.addMatcher(isAnyOf(sectionDetailsasync.fulfilled), (state, action) => {
      state.LogisticsLoader = false
      state.data = action.payload.data
    //   state.LogisticsSubsectionData = action.payload.data.subsection
    //   state.LogisticsFinalFormula = action.payload.data.subsectionFinalFormula
    })

    builder.addMatcher(isAnyOf(sectionDetailsasync.rejected), (state, action) => {
      state.LogisticsLoader = false
      state.LogisticsError = action.payload
    })

    // builder.addMatcher(isAnyOf(checkDeleteProjectDataOtherCostAsync.pending), (state, action) => {
    //   state.deleteDetails.deleteLoader = true
    // })
    // builder.addMatcher(isAnyOf(checkDeleteProjectDataOtherCostAsync.fulfilled), (state, action) => {
    //   state.deleteDetails.deleteLoader = false
    //   state.deleteDetails.deleteState = action.payload
    // })
    // builder.addMatcher(isAnyOf(checkDeleteProjectDataOtherCostAsync.rejected), (state, action) => {
    //   state.deleteDetails.deleteLoader = false
    //   state.deleteDetails.deleteError = action.payload.message
    // })

    // builder.addMatcher(isAnyOf(updateProjectDataOtherCostAsync.pending), (state, action) => {
    //   state.updateDetails.updateLoader = true
    // })
    // builder.addMatcher(isAnyOf(updateProjectDataOtherCostAsync.fulfilled), (state, action) => {
    //   state.updateDetails.updateLoader = false
    //   state.updateDetails.updateState = action.payload
    // })
    // builder.addMatcher(isAnyOf(updateProjectDataOtherCostAsync.rejected), (state, action) => {
    //   state.updateDetails.updateLoader = false
    //   state.updateDetails.updateError = action.payload.message
    // })

    // builder.addMatcher(isAnyOf(updateProjectDataAsync.pending), (state, action) => {
    //   state.addDetails.addLoader = true
    // })
    // builder.addMatcher(isAnyOf(updateProjectDataAsync.fulfilled), (state, action) => {
    //   state.addDetails.addLoader = false
    //   state.addDetails.addState = action.payload
    // })
    // builder.addMatcher(isAnyOf(updateProjectDataAsync.rejected), (state, action) => {
    //   state.addDetails.addLoader = false
    //   state.addDetails.addError = action.payload.message
    // })
  },
//   reducers: {
//     deleteData: (state) => {
//       state.deleteDetails.deleteLoader = false
//       state.deleteDetails.deleteState = []
//       state.deleteDetails.deleteError = ''
//     },
//     updateData: (state) => {
//       state.updateDetails.updateLoader = false
//       state.updateDetails.updateState = []
//       state.updateDetails.updateError = ''
//     },
//     addData: (state) => {
//       state.addDetails.addLoader = false
//       state.addDetails.addState = []
//       state.addDetails.addError = ''
//     },
//   },
})

// export const { deleteData, updateData, addData } = otherCostLogisticsSlice.actions
export default otherCostLogisticsSlice.reducer