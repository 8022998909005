import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const getEmbedPowerBiTokenAsync = createAsyncThunk(
  'managementreport/getEmbedPowerBiToken',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', '/getEmbedToken', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
