import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getSummaryChartData } from "../../async/ice/iceasync";

const initialState = {
    summaryChartLoader: false,
    summaryChartError: "",
  summaryChart_state: [],
};

export const summaryChartSlice = createSlice({
  name: "summaryChart",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getSummaryChartData.pending), (state) => {
      state.summaryChartLoader = true;
    });
    builder.addMatcher(isAnyOf(getSummaryChartData.fulfilled), (state, action) => {
      state.summaryChartLoader = false;
      state.summaryChart_state = action.payload;
      state.summaryChartError = "";
    });
    builder.addMatcher(isAnyOf(getSummaryChartData.rejected), (state, action) => {
      state.summaryChartLoader = false;
      state.summaryChartError = action.payload;
    });
  },
  reducers: {
    emptysummaryChartSlice: (state) => {
      state.summaryChartLoader = false;
      state.summaryChartError = "";
      state.summaryChart_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default summaryChartSlice.reducer;
