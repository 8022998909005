import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getInbox } from "./rfi-rfp.async";

const initialState = {
    inboxLoader: false,
    inboxError: "",
  inbox_state: [],
};

export const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getInbox.pending), (state) => {
      state.inboxLoader = true;
    });
    builder.addMatcher(isAnyOf(getInbox.fulfilled), (state, action) => {
      state.inboxLoader = false;
      state.inbox_state = action.payload;
      state.inboxError = "";
    });
    builder.addMatcher(isAnyOf(getInbox.rejected), (state, action) => {
      state.inboxLoader = false;
      state.inboxError = action.payload;
    });
  },
  reducers: {
    emptyinboxSlice: (state) => {
      state.inboxLoader = false;
      state.inboxError = "";
      state.inbox_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default inboxSlice.reducer;
