import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getCommodityKPIsAsync } from 'src/services/shouldCostAIBased.services'

const initialState = {
  getCommodityKPIsLoader: false,
  getCommodityKPIs: [],
  getCommodityKPIsError: '',
}

export const getCommodityKPIsSlice = createSlice({
  name: 'get-commodity-kpis',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getCommodityKPIsAsync.pending), (state) => {
      state.getCommodityKPIsLoader = true
    })
    builder.addMatcher(isAnyOf(getCommodityKPIsAsync.fulfilled), (state, action) => {
      state.getCommodityKPIsLoader = false
      state.getCommodityKPIs = action.payload.data
    })
    builder.addMatcher(isAnyOf(getCommodityKPIsAsync.rejected), (state, action) => {
      state.getCommodityKPIsLoader = false
      state.getCommodityKPIsError = action.payload.message
    })
  },
  reducers: {
    resetCommodityKPIs: () => initialState,
  },
})

export const { resetCommodityKPIs } = getCommodityKPIsSlice.actions

export default getCommodityKPIsSlice.reducer
