
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getPieChartMonitorResponses } from "./rfi-rfp.async";

const initialState = {
  pieChartDataLoader: false,
  pieChartDataError: "",
  pieChartData_state: [],
};

export const pieChartDataSlice = createSlice({
  name: "pieChartData",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getPieChartMonitorResponses.pending), (state) => {
      state.pieChartDataLoader = true;
    });
    builder.addMatcher(isAnyOf(getPieChartMonitorResponses.fulfilled), (state, action) => {
      state.pieChartDataLoader = false;
      state.pieChartData_state = action.payload;
      state.pieChartDataError = "";
    });
    builder.addMatcher(isAnyOf(getPieChartMonitorResponses.rejected), (state, action) => {
      state.pieChartDataLoader = false;
      state.pieChartDataError = action.payload;
    });
  },
  reducers: {
    emptypieChartDataSlice: (state) => {
      state.pieChartDataLoader = false;
      state.pieChartDataError = "";
      state.pieChartData_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default pieChartDataSlice.reducer;
