import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getFiltersDataAsync } from 'src/services/shouldCostAIBased.services'

const initialState = {
  getFiltersDataLoader: false,
  getFiltersData: [],
  getFiltersDataError: '',
}

export const getFiltersDataSlice = createSlice({
  name: 'get-filters-data',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getFiltersDataAsync.pending), (state) => {
      state.getFiltersDataLoader = true
    })
    builder.addMatcher(isAnyOf(getFiltersDataAsync.fulfilled), (state, action) => {
      state.getFiltersDataLoader = false
      state.getFiltersData = action.payload.data
    })
    builder.addMatcher(isAnyOf(getFiltersDataAsync.rejected), (state, action) => {
      state.getFiltersDataLoader = false
      state.getFiltersDataError = action.payload.message
    })
  },
})

export default getFiltersDataSlice.reducer
