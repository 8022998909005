import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getSkuTableDataAsync } from 'src/services/shouldCostAIBased.services'

const initialState = {
  getSkuTableLoader: false,
  getSkuTableData: [],
  getSkuTableError: '',
}

export const getSkuTableSlice = createSlice({
  name: 'get-sku-table-data',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getSkuTableDataAsync.pending), (state) => {
      state.getSkuTableLoader = true
    })
    builder.addMatcher(isAnyOf(getSkuTableDataAsync.fulfilled), (state, action) => {
      state.getSkuTableLoader = false
      state.getSkuTableData = action.payload.data
    })
    builder.addMatcher(isAnyOf(getSkuTableDataAsync.rejected), (state, action) => {
      state.getSkuTableLoader = false
      state.getSkuTableError = action?.payload?.message
    })
  },
})

export default getSkuTableSlice.reducer
