import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getIndividualSupplierInformation } from 'src/redux/async'

const initialState = {
  individualSupplierInformationLoader: false,
  individualSupplierInformationError: '',
  individualSupplierInformation: [],
}

export const supplierInformationSlice = createSlice({
  name: 'SupplierInformation',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getIndividualSupplierInformation.pending), (state) => {
      state.individualSupplierInformationLoader = true
    })
    builder.addMatcher(isAnyOf(getIndividualSupplierInformation.fulfilled), (state, action) => {
      state.individualSupplierInformationLoader = false
      state.individualSupplierInformation = action.payload
      state.individualSupplierInformationError = ''
    })
    builder.addMatcher(isAnyOf(getIndividualSupplierInformation.rejected), (state, action) => {
      state.individualSupplierInformationLoader = false
      state.individualSupplierInformationError = action.payload
    })
  },
  reducers: {
    emptyIndividualSupplierInformationSlice: (state) => {
      state.individualSupplierInformationLoader = false
      state.individualSupplierInformationError = ''
      state.individualSupplierInformation = []
    },
  },
})

export const { emptyIndividualSupplierInformationSlice } = supplierInformationSlice.actions

export default supplierInformationSlice.reducer
