import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  createMaterialAsync,
  getMaterialAsync,
  updateMaterialAsync,
  subSectionAsync,
} from './material.async'

const initialState = {
  materialLoader: false,
  materialsData: [],
  materialCreate: '',
  subSectionData: [],
  materialsError: '',
}

export const materialSlice = createSlice({
  name: 'materials',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getMaterialAsync.pending,
        createMaterialAsync.pending,
        subSectionAsync.pending,
        updateMaterialAsync.pending,
      ),
      (state) => {
        state.materialLoader = true
      },
    )
    builder.addMatcher(isAnyOf(getMaterialAsync.fulfilled), (state, action) => {
      state.materialLoader = false
      state.materialsData = action.payload.data
    })
    builder.addMatcher(isAnyOf(createMaterialAsync.fulfilled), (state, action) => {
      state.materialLoader = false
      state.materialCreate = action.payload
    })
    builder.addMatcher(isAnyOf(updateMaterialAsync.fulfilled), (state, action) => {
      state.materialLoader = false
    })
    builder.addMatcher(isAnyOf(subSectionAsync.fulfilled), (state, action) => {
      state.materialLoader = false
      state.subSectionData = action.payload
    })
    builder.addMatcher(
      isAnyOf(
        getMaterialAsync.rejected,
        createMaterialAsync.rejected,
        subSectionAsync.rejected,
        updateMaterialAsync.rejected,
      ),
      (state, action) => {
        state.materialLoader = false
        state.materialsError = action.payload.message
      },
    )
  },
  reducers: {
    resetMaterialDetails: () => initialState,
  },
})

export const { resetMaterialDetails } = materialSlice.actions

export default materialSlice.reducer
