import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getProductsByCategoryAsync } from 'src/redux/async/vcn/vcn.async'

const initialState = {
  productsbycategoryLoader: false,
  productsbycategoryData: [],
  defaultProductbycategory: [],
  productsbycategoryError: '',
}

export const productsByCategorySlice = createSlice({
  name: 'vcn-exposure/products',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getProductsByCategoryAsync.pending), (state) => {
      state.productsbycategoryLoader = true
    })
    builder.addMatcher(isAnyOf(getProductsByCategoryAsync.fulfilled), (state, action) => {
      state.productsbycategoryLoader = false
      state.productsbycategoryData = action.payload.data
      state.defaultProductbycategory = action.payload.default
    })
    builder.addMatcher(isAnyOf(getProductsByCategoryAsync.rejected), (state, action) => {
      state.productsbycategoryLoader = false
      state.productsbycategoryError = action.payload.message
    })
  },
  reducers: {
    resetProductDetails: () => initialState
  },
})
export const { resetProductDetails } = productsByCategorySlice.actions
export default productsByCategorySlice.reducer
