import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getTotalCommodities } from 'src/redux/async/ice/overviewasync'

const initialState = {
  overviewLoader: false,
  overviewData: [],
  overviewError: false,
}

export const iceOverviewSlice = createSlice({
  name: 'iceOverviewData',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getTotalCommodities.pending), (state) => {
      state.overviewLoader = true
    })
    builder.addMatcher(isAnyOf(getTotalCommodities.fulfilled), (state, action) => {
      state.overviewLoader = false
      state.overviewData = action.payload
      state.overviewError = ''
    })

    builder.addMatcher(isAnyOf(getTotalCommodities.rejected), (state, action) => {
      state.overviewLoader = false
      state.overviewError = action.payload
    })
  },
})

export default iceOverviewSlice.reducer
