import { createSlice, isAnyOf } from '@reduxjs/toolkit'

const initialState = {
    selectedSuppliersstate:[]
}

export const selectedSuppliersSlice = createSlice({
  name: 'selectedSuppliers',
  initialState,
  reducers:{
    setSelectedSuppliers(state,action){
        state.selectedSuppliersstate=action.payload
    }
  }
})
export const { setSelectedSuppliers} = selectedSuppliersSlice.actions

export default selectedSuppliersSlice.reducer