import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getCommodityLibAsync } from 'src/redux/async/libraries/libcommodityasync';

const initialState = {
  commodityLibLoader: false,
  commodityLibError: '',
  commodityLib_state: [],
}

export const commodityLibrariesSlice = createSlice({
    name:"commodityLibrariesData",
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(getCommodityLibAsync.pending), (state) => {
          state.commodityLibLoader = true;
        });
        builder.addMatcher(isAnyOf(getCommodityLibAsync.fulfilled), (state, action) => {
          state.commodityLibLoader = false;
          state.commodityLib_state = action.payload;
          state.commodityLibError = "";
        });
        builder.addMatcher(isAnyOf(getCommodityLibAsync.rejected), (state, action) => {
          state.commodityLibLoader = false;
          state.commodityLibError = action.payload;
        });
      },
})

export default commodityLibrariesSlice.reducer;