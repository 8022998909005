import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getAllTemplates } from "./rfi-rfp.async";

const initialState = {
    allTemplateDetailsLoader: false,
    allTemplateDetailsError: "",
  allTemplateDetails_state: [],
};


export const allTemplateDetailsSlice = createSlice({
  name: "allTemplateDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getAllTemplates.pending), (state) => {
      state.allTemplateDetailsLoader = true;
    });
    builder.addMatcher(isAnyOf(getAllTemplates.fulfilled), (state, action) => {
      state.allTemplateDetailsLoader = false;
      state.allTemplateDetails_state = action.payload;
      state.allTemplateDetailsError = "";
    });
    builder.addMatcher(isAnyOf(getAllTemplates.rejected), (state, action) => {
      state.allTemplateDetailsLoader = false;
      state.allTemplateDetailsError = action.payload;
    });
  },
  reducers: {
    emptyallTemplateDetailsSlice: (state) => {
      state.allTemplateDetailsLoader = false;
      state.allTemplateDetailsError = "";
      state.allTemplateDetails_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default allTemplateDetailsSlice.reducer;
