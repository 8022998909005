import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getScoreCardKPIs } from 'src/redux/async/vcn/vcn.async';

const initialState = {
  kpiLoader: false,
  kpiError: '',
  kpi_state: [],
}

export const scoreCardKPIsSlice = createSlice({
    name:"commodityLibrariesData",
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(getScoreCardKPIs.pending), (state) => {
          state.kpiLoader = true;
        });
        builder.addMatcher(isAnyOf(getScoreCardKPIs.fulfilled), (state, action) => {
          state.kpiLoader = false;
          state.kpi_state = action.payload;
          state.kpiError = "";
        });
        builder.addMatcher(isAnyOf(getScoreCardKPIs.rejected), (state, action) => {
          state.kpiLoader = false;
          state.kpiError = action.payload;
        });
      },
})

export default scoreCardKPIsSlice.reducer;