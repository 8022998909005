import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const getMaterialAsync = createAsyncThunk(
  'shouldcost/getMaterial',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/getElement?project_id=${payload}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const createMaterialAsync = createAsyncThunk(
  'shouldcost/createMaterial',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/element`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const deleteMaterialAsync = createAsyncThunk(
  'should-cost/delete-material',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/deleteElement`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const updateMaterialAsync = createAsyncThunk(
  'should-cost/update-material',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/updateElement`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const subSectionAsync = createAsyncThunk(
  'shouldcost/subSection',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/subsection`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
