import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getProjectwiseInbox } from "./rfi-rfp.async";

const initialState = {
    projectwise_inboxLoader: false,
    projectwise_inboxError: "",
    projectwise_inbox_state: [],
};

export const projectwise_inboxSlice = createSlice({
  name: "projectwise_inbox",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getProjectwiseInbox.pending), (state) => {
      state.projectwise_inboxLoader = true;
    });
    builder.addMatcher(isAnyOf(getProjectwiseInbox.fulfilled), (state, action) => {
      state.projectwise_inboxLoader = false;
      state.projectwise_inbox_state = JSON.parse(action.payload?.data);
      state.projectwise_inboxError = "";
    });
    builder.addMatcher(isAnyOf(getProjectwiseInbox.rejected), (state, action) => {
      state.projectwise_inboxLoader = false;
      state.projectwise_inboxError = action.payload;
    });
  },
  reducers: {
    emptyinboxSlice: (state) => {
      state.projectwise_inboxLoader = false;
      state.projectwise_inboxError = "";
      state.projectwise_inbox_state = [];
    },
  },
});

export default projectwise_inboxSlice.reducer;
