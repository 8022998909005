import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getStatus } from "./rfi-rfp.async";

const initialState = {
  statusLoader: false,
  statusError: "",
  status_state: [],
};

export const statusSlice = createSlice({
  name: "status",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getStatus.pending), (state) => {
      state.statusLoader = true;
    });
    builder.addMatcher(isAnyOf(getStatus.fulfilled), (state, action) => {
      state.statusLoader = false;
      state.status_state = action.payload;
      state.statusError = "";
    });
    builder.addMatcher(isAnyOf(getStatus.rejected), (state, action) => {
      state.statusLoader = false;
      state.statusError = action.payload;
    });
  },
  reducers: {
    emptystatusSlice: (state) => {
      state.statusLoader = false;
      state.statusError = "";
      state.status_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;

export default statusSlice.reducer;
