import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const getMarginOverhead = createAsyncThunk(
  'shouldcost/othercost/overheadmargin',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', `/v1/sectionDetails${payload}`,[] , {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const updateProjectDataOtherCostAsync = createAsyncThunk(
    'otherCost/updateProjectDataOtherCost',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', 'v1/updateProjectData', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

  export const checkDeleteProjectDataOtherCostAsync = createAsyncThunk(
    'otherCost/checkDelete',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', 'v1/deleteProjectData', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

  export const deleteProjectDataOtherCostAsync = createAsyncThunk(
    'otherCost/deleteProjectData',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', 'v1/projectDataDelete', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )
  
  export const finalFormulaasync = createAsyncThunk(
    'otherCost/setfinalFormula',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('POST', 'v2/conversion/finalFormula', payload, {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )

export const sectionDetailsasync = createAsyncThunk(
    'otherCost/setfinalFormula',
    async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
      return await AxiosClient('GET', `v1/sectionDetails${payload}`, [], {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      })
    },
  )