import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getModifyShouldCostRowsAsync } from 'src/redux/async/libraries/buildshouldcost'
import { deleteModifyShouldCostProject } from 'src/redux/async/ice/iceasync'

const initialState = {
  modifyCostLoader: false,
  modifyCostData: [],
  modifyCostError: '',
  projectDeleteLoader: false,
}

export const modifyCostSlice = createSlice({
  name: 'modifyCostData',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getModifyShouldCostRowsAsync.pending), (state) => {
      state.modifyCostLoader = true
    })
    builder.addMatcher(isAnyOf(deleteModifyShouldCostProject.pending), (state) => {
      state.projectDeleteLoader = true
    })

    builder.addMatcher(isAnyOf(getModifyShouldCostRowsAsync.fulfilled), (state, action) => {
      state.modifyCostLoader = false
      state.modifyCostData = action.payload
    })
    builder.addMatcher(isAnyOf(deleteModifyShouldCostProject.fulfilled), (state) => {
      state.projectDeleteLoader = false
    })

    builder.addMatcher(isAnyOf(getModifyShouldCostRowsAsync.rejected), (state, action) => {
      state.modifyCostLoader = false
      state.modifyCostError = action.payload
    })
    builder.addMatcher(isAnyOf(deleteModifyShouldCostProject.rejected), (state) => {
      state.projectDeleteLoader = false
    })
  },
})

export default modifyCostSlice.reducer
