import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getShouldCostAsync } from 'src/redux/async/vcn/vcn.async'

const initialState = {
  shouldCostLoader: false,
  shouldCostData: [],
  shouldCostError: '',
}

export const shouldCostPoSlice = createSlice({
  name: 'vcn-exposure/shouldCost',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getShouldCostAsync.pending), (state) => {
      state.shouldCostLoader = true
    })
    builder.addMatcher(isAnyOf(getShouldCostAsync.fulfilled), (state, action) => {
      state.shouldCostLoader = false
      state.shouldCostData = action.payload
    })
    builder.addMatcher(isAnyOf(getShouldCostAsync.rejected), (state, action) => {
      state.shouldCostLoader = false
      state.shouldCostError = action.payload.message
    })
  },
  reducers: {
    resetshouldCostDetails: () => initialState,
  },
})
export const { resetshouldCostDetails } = shouldCostPoSlice.actions
export default shouldCostPoSlice.reducer
