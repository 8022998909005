
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getCurrentStatus } from "./rfi-rfp.async";

const initialState = {
  mailStatusLoader: false,
  mailStatusError: "",
  mailStatus_state: [],
};

export const mailStatusSlice = createSlice({
  name: "mailStatus",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getCurrentStatus.pending), (state) => {
      state.mailStatusLoader = true;
    });
    builder.addMatcher(isAnyOf(getCurrentStatus.fulfilled), (state, action) => {
      state.mailStatusLoader = false;
      state.mailStatus_state = action?.payload?.recordset[0]?.id|| [];
      state.mailStatusError = "";
    });
    builder.addMatcher(isAnyOf(getCurrentStatus.rejected), (state, action) => {
      state.mailStatusLoader = false;
      state.mailStatusError = action?.payload;
    });
  },
  reducers: {
    emptymailStatusSlice: (state) => {
      state.mailStatusLoader = false;
      state.mailStatusError = "";
      state.mailStatus_state = [];
    },
  },
});

// export const { emptyNewsSlice } = otpSlice.actions;


export default mailStatusSlice.reducer;
