import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getVendorData } from 'src/redux/async/ice/overviewasync'

const initialState = {
  vendorChartLoader: false,
  vendorChartData: [],
  vendorChartError: '',
}

const tariffchartSlice = createSlice({
  name: 'vendorChartSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getVendorData.pending), (state) => {
      state.vendorChartLoader = true
    })

    builder.addMatcher(isAnyOf(getVendorData.fulfilled), (state, action) => {
      state.vendorChartLoader = false
      state.vendorChartData = action.payload
    })

    builder.addMatcher(isAnyOf(getVendorData.rejected), (state, action) => {
      state.vendorChartLoader = false
      action.vendorChartError = action.payload
    })
  },
})

export default tariffchartSlice.reducer
