import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
    //levels
    getCommoditiesDataAsync,
    getLevel1DataAsync,
    getLevel2DataAsync,
    getLevel3DataAsync,
    getCountryDataAsync,
    // basket
    getBasketAsync,
    createBasketAsync,
    deleteBasketAsync,
    //commodity
    getIcrBasketCommodityTableDataAsync,
    deleteIcrBasketCommodityTableDataAsync,
    addIcrBasketCommodityTableDataAsync,
    UpdateIcrBasketCommodityTableDataAsync
} from 'src/redux/async'

const initialState = {
    BasketLoader: false,
    //levels
    getCommoditiesData: [],
    getLevel1Data: [],
    getLevel2Data: [],
    getLevel3Data: [],
    getCountryData: [],
    // basket
    getBasket: [],
    createBasket: [],
    deleteBasket: [],
    //commodity
    getIcrBasketCommodityTableData: [],
    deleteIcrBasketCommodityTableData: [],
    addIcrBasketCommodityTableData: [],
    UpdateIcrBasketCommodityTableData: [],
    BasketError: '',
}

export const BasketSlice = createSlice({
    name: 'Basket Data',
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(
            //levels
            getCommoditiesDataAsync.pending,
            getLevel1DataAsync.pending,
            getLevel2DataAsync.pending,
            getLevel3DataAsync.pending,
            getCountryDataAsync.pending,
            // basket
            getBasketAsync.pending,
            createBasketAsync.pending,
            deleteBasketAsync.pending,
            //commodity
            getIcrBasketCommodityTableDataAsync.pending,
            deleteIcrBasketCommodityTableDataAsync.pending,
            addIcrBasketCommodityTableDataAsync.pending,
            UpdateIcrBasketCommodityTableDataAsync.pending
        ), (state) => {
            state.BasketLoader = true
        })
        //levels
        builder.addMatcher(isAnyOf(getCommoditiesDataAsync.fulfilled), (state, action) => {
            state.BasketLoader = false
            state.getCommoditiesData = action.payload
        })
        builder.addMatcher(isAnyOf(getLevel1DataAsync.fulfilled), (state, action) => {
            state.BasketLoader = false
            state.getLevel1Data = action.payload
        })
        builder.addMatcher(isAnyOf(getLevel2DataAsync.fulfilled), (state, action) => {
            state.BasketLoader = false
            state.getLevel2Data = action.payload
        })
        builder.addMatcher(isAnyOf(getLevel3DataAsync.fulfilled), (state, action) => {
            state.BasketLoader = false
            state.getLevel3Data = action.payload
        })
        builder.addMatcher(isAnyOf(getCountryDataAsync.fulfilled), (state, action) => {
            state.BasketLoader = false
            state.getCountryData = action.payload
        })
        // basket
        builder.addMatcher(isAnyOf(getBasketAsync.fulfilled), (state, action) => {
            state.BasketLoader = false
            state.getBasket = action.payload
        })
        builder.addMatcher(isAnyOf(createBasketAsync.fulfilled), (state, action) => {
            state.BasketLoader = false
            state.createBasket = action.payload
        })
        builder.addMatcher(isAnyOf(deleteBasketAsync.fulfilled), (state, action) => {
            state.BasketLoader = false
            state.deleteBasket = action.payload
        })
        //commodity
        builder.addMatcher(isAnyOf(getIcrBasketCommodityTableDataAsync.fulfilled), (state, action) => {
            state.BasketLoader = false
            state.getIcrBasketCommodityTableData = action.payload
        })
        builder.addMatcher(isAnyOf(deleteIcrBasketCommodityTableDataAsync.fulfilled), (state, action) => {
            state.BasketLoader = false
            state.deleteIcrBasketCommodityTableData = action.payload
        })
        builder.addMatcher(isAnyOf(addIcrBasketCommodityTableDataAsync.fulfilled), (state, action) => {
            state.BasketLoader = false
            state.addIcrBasketCommodityTableData = action.payload
        })
        builder.addMatcher(isAnyOf(UpdateIcrBasketCommodityTableDataAsync.fulfilled), (state, action) => {
            state.BasketLoader = false
            state.UpdateIcrBasketCommodityTableData = action.payload
        })
        builder.addMatcher(isAnyOf(
            //levels
            getCommoditiesDataAsync.rejected,
            getLevel1DataAsync.rejected,
            getLevel2DataAsync.rejected,
            getLevel3DataAsync.rejected,
            getCountryDataAsync.rejected,
            // basket
            getBasketAsync.rejected,
            createBasketAsync.rejected,
            deleteBasketAsync.rejected,
            //commodity
            getIcrBasketCommodityTableDataAsync.rejected,
            deleteIcrBasketCommodityTableDataAsync.rejected,
            addIcrBasketCommodityTableDataAsync.rejected,
            UpdateIcrBasketCommodityTableDataAsync.rejected
        ), (state, action) => {
            state.BasketLoader = false
            state.BasketError = action.payload.message
        })
    },
})

export default BasketSlice.reducer;