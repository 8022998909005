import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getCurrentMachine, selectTemplatesAsync, setBuildShouldCostDetails } from 'src/redux/async/ice/buildshouldcostdetails/buildshouldcostdetailsasync';
import { getProjectDetailAsync } from 'src/redux/async/ice/buildshouldcostdetails/projectDetailAsync';

const initialState = {
  details: {
    category: '',
    subcategory: '',
    categoryGroup: '',
    articleDescription: '',
    selectedSKUrow: '',
    doesExist: false,
    project_id: null,
    projectDescription: '',
    projectCountry: 'USA',
  },
  projectDetails: {
    detailsLoader: false,
    detailsData: [],
    selectTemplateData: {},
    detailsError: '',
  },
  currentMachineDetails: {},
  materialMachineDetails: [],
  material: {
    currentMaterialId: 0,
  },
  conversion: {
    sectionId: 2,
    defaultList: [
      { name: 'Machine', type: 1 },
      { name: 'Utility', type: 2 },
      { name: 'Labor', type: 3 },
    ],
    newAdded: [],
  },
  specifications: {
    list: [],
  },
  detailsLoader: false,
  detailsError: '',




};

export const buildShouldCostDetailsSlice = createSlice({
  name: 'buildShouldCostDetails',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(setBuildShouldCostDetails.pending), (state) => {
      state.detailsLoader = true;
    });

    builder.addMatcher(isAnyOf(setBuildShouldCostDetails.fulfilled), (state, action) => {
      state.detailsLoader = false;
      state.details.category = action.payload.category_ah4;
      state.details.subcategory = action.payload.subcategory_ah5;
      state.details.categoryGroup = action.payload.ah3;
      state.details.articleDescription = action.payload.description;
      state.details.selectedSKUrow = action.payload.id;
      state.details.doesExist = action.payload.doesExist || false;
      state.details.project_id = action.payload.project_id || null;
      state.details.projectDescription = action.payload.name || '';
      state.details.projectCountry = action.payload.country || 'USA';
    });

    builder.addMatcher(isAnyOf(setBuildShouldCostDetails.rejected), (state, action) => {
      state.detailsLoader = false;
      state.detailsError = action.payload;
    });

    builder.addMatcher(isAnyOf(getProjectDetailAsync.pending), (state) => {
      state.projectDetails.detailsLoader = true;
    });
    builder.addMatcher(isAnyOf(getProjectDetailAsync.fulfilled), (state, action) => {
      state.projectDetails.detailsLoader = false;
      state.projectDetails.detailsData = action.payload;
    });
    builder.addMatcher(isAnyOf(getProjectDetailAsync.rejected), (state, action) => {
      state.projectDetails.detailsLoader = false;
      state.projectDetails.detailsError = action.payload.message;
    });
    builder.addMatcher(isAnyOf(selectTemplatesAsync.pending), (state) => {
      state.projectDetails.detailsLoader = true;
    });
    builder.addMatcher(isAnyOf(selectTemplatesAsync.fulfilled), (state, action) => {
      state.projectDetails.detailsLoader = false;
      state.projectDetails.selectTemplateData = action.payload;
    });
    builder.addMatcher(isAnyOf(selectTemplatesAsync.rejected), (state, action) => {
      state.projectDetails.detailsLoader = false;
      state.projectDetails.detailsError = action.payload.message;
    });
    builder.addMatcher(isAnyOf(getCurrentMachine.fulfilled), (state, action) => {
      state.currentMachineDetails = action.payload.data
    })
    builder.addMatcher(isAnyOf(getCurrentMachine.rejected), (state, action) => {
      state.projectDetails.detailsError = action.payload.message
    })
  },
  reducers: {
    emptyShouldCostDetails: () => initialState,
    emptyProjectDetails: (state) => {
      state.projectDetails.detailsLoader = false;
      state.projectDetails.detailsData = [];
      state.projectDetails.detailsError = '';
    },
    emptyConversion: (state) => {
      state.conversion.newAdded = [];
    },
    setCurrentMaterial: (state, action) => {
      state.material.currentMaterialId = action.payload;
    },
    setMaterialMachine: (state, action) => {
      state.materialMachineDetails = [...action.payload];
    },
    addNewConversion: (state, action) => {
      state.conversion.newAdded = [...action.payload];
    },
    setSpecifications: (state, action) => {
      state.specifications.list = [...action.payload];
    },
    setProjectDetailsExisting: (state, action) => {
      state.projectDetails.detailsData = action.payload;
    },
  },
});
export const {
  emptyShouldCostDetails,
  emptyProjectDetails,
  setMaterialMachine,
  setCurrentMaterial,
  addNewConversion,
  setSpecifications,
  setProjectDetailsExisting,
} = buildShouldCostDetailsSlice.actions;
export default buildShouldCostDetailsSlice.reducer;
