import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  getConversionLibAsync,
  createConversionAsync,
  deleteConversionAsync,
} from 'src/redux/async/libraries/conversion'

const initialState = {
  conversionLibLoader: false,
  conversionLibError: '',
  conversionLib_state: [],
  conversionDeleteLoader: false,
}

export const conversionLibrariesSlice = createSlice({
  name: 'conversionLibrariesData',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(getConversionLibAsync.pending, createConversionAsync.pending),
      (state) => {
        state.conversionLibLoader = true
      },
    )
    builder.addMatcher(isAnyOf(deleteConversionAsync.pending), (state) => {
      state.conversionDeleteLoader = true
    })
    builder.addMatcher(isAnyOf(getConversionLibAsync.fulfilled), (state, action) => {
      state.conversionLibLoader = false
      state.conversionLib_state = action.payload
      state.conversionLibError = ''
    })
    builder.addMatcher(isAnyOf(createConversionAsync.fulfilled), (state) => {
      state.conversionLibLoader = false
      state.conversionLibError = ''
    })
    builder.addMatcher(isAnyOf(deleteConversionAsync.fulfilled), (state) => {
      state.conversionDeleteLoader = false
      
    })
    builder.addMatcher(isAnyOf(deleteConversionAsync.rejected), (state) => {
      state.conversionDeleteLoader = false
    })
    builder.addMatcher(
      isAnyOf(getConversionLibAsync.rejected, createConversionAsync.rejected),
      (state, action) => {
        state.conversionLibLoader = false
        state.conversionLibError = action.payload
      },
    )
  },
})

export default conversionLibrariesSlice.reducer
