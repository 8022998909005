import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getdutytariffLibAsync } from 'src/redux/async/libraries/dutytariffasync';

const initialState = {
  dutytariffLibLoader: false,
  dutytariffLibError: '',
  dutytariffLib_state: [],
}

export const dutytariffLibrariesSlice = createSlice({
    name:"dutytariffLibrariesData",
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(getdutytariffLibAsync.pending), (state) => {
          state.dutytariffLibLoader = true;
        });
        builder.addMatcher(isAnyOf(getdutytariffLibAsync.fulfilled), (state, action) => {
          state.dutytariffLibLoader = false;
          state.dutytariffLib_state = action.payload;
          state.dutytariffLibError = "";
        });
        builder.addMatcher(isAnyOf(getdutytariffLibAsync.rejected), (state, action) => {
          state.dutytariffLibLoader = false;
          state.dutytariffLibError = action.payload;
        });
      },
})

export default dutytariffLibrariesSlice.reducer;