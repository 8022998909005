import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const setBuildShouldCostDetails = createAsyncThunk(
  'api/buildShouldCostDetails',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return payload
  },
)

export const getFormulaItems = createAsyncThunk(
  'shouldcost/formulaItems',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', 'v1/formulaItem', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const setFinalProject = createAsyncThunk(
  'shouldcost/set-final-project',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', '/v1/setfinalproject', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const updateProject = createAsyncThunk(
  'shouldcost/update-project',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', 'v1/projectUpdate', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getExistingTemplatesAsync = createAsyncThunk(
  'massUpload/getExistingTemplates',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'GET',
      `/v1/existingTemplate/rows?page=1&text=&group=&category=${
        payload || ''
      }&subcategory=&country=&status=`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

export const selectTemplatesAsync = createAsyncThunk(
  'massUpload/selectTemplate',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/template`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getCurrentMachine = createAsyncThunk(
  'shouldcost/getCurrentMachine',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/getSelectedMachine`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
