import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const getMachineLibAsync = createAsyncThunk(
  'libraries/machine',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', 'v1/getmachines', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getCountriesDataAsync = createAsyncThunk(
  'machine/create-machine-countries',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', 'v1/conversion/countries', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const createMachineAsync = createAsyncThunk(
  'machine/create-machine',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', 'v1/machines/create', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const updateMachineAsync = createAsyncThunk(
  'machine/create-machine',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    let machineId = payload.id
    delete payload.id
    return await AxiosClient('POST', `v1/updatemachines/${machineId}`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const deleteMachineAsync = createAsyncThunk(
  'machine/delete-machine',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', '/v1/machine/delete', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
