import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const getConversionLibAsync = createAsyncThunk(
  'libraries/conversion',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', '/v1/getConversions', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const createConversionAsync = createAsyncThunk(
  'libraries/createConversion',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', '/v1/conversions', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const updateConversionAsync = createAsyncThunk(
  'libraries/updateConversion',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    let conversionId = payload.id
    delete payload.id
    return await AxiosClient('POST', `/v1/conversions/update/${conversionId}`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const deleteConversionAsync = createAsyncThunk(
  'libraries/deleteConversion',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', '/v1/conversion/delete', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
