import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from '../../AxiosClient'

export const getProductsFilter = createAsyncThunk(
  'bomAnalysis/getproductfilter',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', `/v1/bom-analysis/get-all-products-filter`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getProductNameFilter = createAsyncThunk(
  'bomAnalysis/getproductnamefilter',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', `/v1/bom-analysis/scenario-builder/get-all-products`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getProductsTree = createAsyncThunk(
  'bomAnalysis/getproducttree',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/bom-analysis/show-all-product-hierarchy`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getProductsBOM = createAsyncThunk(
  'bomAnalysis/getproductbom',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/bom-analysis/show-product-hierarchy`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getProductsBOMForPart = createAsyncThunk(
  'bomAnalysis/getproductbomforpart',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/bom-analysis/show-all-children-hierarchy`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getVendorComparisonFilters = createAsyncThunk(
  'bomAnalysis/getvendorcomparisonfilters',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/bom-analysis/vendors-filter`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getVendorComparisonData = createAsyncThunk(
  'bomAnalysis/getvendorcomparisondata',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/bom-analysis/compare-vendor`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getVendorComparisonComponentFilter = createAsyncThunk(
  'bomAnalysis/getvendorcomparisoncomponentfilter',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `v1/bom-analysis/component-filter`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getRFPComparisonTableData = createAsyncThunk(
  'bomAnalysis/compare-rfps',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `v1/bom-analysis/compare-rfps`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getRFPKPIs = createAsyncThunk(
  'bomAnalysis/rfp-kpis',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `v1/bom-analysis/rfp-kpi`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

//Scenario Analysis
export const getScenarioData = createAsyncThunk(
  'bomAnalysis/scenario-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', `/v1/bom-analysis/secnario-mockup`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

//Scenario Analysis
export const getMockScenarioData = createAsyncThunk(
  'bomAnalysis/mock-scenario-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      'GET',
      `/v1/bom-analysis/scenario-analysis/pre-configured-scenario`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      },
    )
  },
)

export const getConfiguredScenarioData = createAsyncThunk(
  'bomAnalysis/scenario-configured-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/bom-analysis/configured-scenario`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getScenarioBuilderData = createAsyncThunk(
  'bomAnalysis/scenario-builder-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `v1/bom-analysis/scenario-builder/product-wise-alternate-supplier-details`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getScenarioBuilderAlternateSuppliers = createAsyncThunk(
  'bomAnalysis/scenario-builder/alternate-suppliers',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `v1/bom-analysis/scenario-builder/get-alternate-suppliers`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const updateScenario = createAsyncThunk(
  'bomAnalysis/scenario-builder/update-scenario',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `v1/bom-analysis/scenario-builder/update-alternate-suppliers`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

// Views in Scenario Builder
export const getScenarioViews = createAsyncThunk(
  'bomAnalysis/scenario-builder/get-scenario-views',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', `/v1/bom-analysis/scenario-builder/get-list-scenario-views`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const createScenarioView = createAsyncThunk(
  'bomAnalysis/scenario-builder/create-scenario-view',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/bom-analysis/scenario-builder/create-new-scenario-view`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)