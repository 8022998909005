import { createSlice, isAnyOf } from '@reduxjs/toolkit'

const initialState = {
  shipperidstate: '',
  shipperFilterState: {},
  shipperName: '',
}

export const ShipperSlice = createSlice({
  name: 'ShipperId',
  initialState,
  reducers: {
    setShipperID(state, action) {
      state.shipperidstate = action.payload
    },
    setShipperName(state, action) {
      state.shipperName = action.payload
    },
    clearShipperID(state) {
      state.shipperidstate = ''
    },
    clearShipperName(state) {
      state.shipperName = ''
    },
  },
})
export const {
  setShipperID,
  setShipperName,
  clearShipperID,
  clearShipperName
} = ShipperSlice.actions

export default ShipperSlice.reducer