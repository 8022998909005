import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const getBuildShouldCostRowsAsync = createAsyncThunk(
  'api/shouldCostRows',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', '/v1/buildShouldCost/rows', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getModifyShouldCostRowsAsync = createAsyncThunk(
  'api/modifyshouldCostRows',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', '/v1/modifyProject/rows', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getArticleCountShouldCostSummary = createAsyncThunk(
  'api/modifyshouldCostRows',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', 'shouldCostSummary/articleCount', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getKPIShouldCostSummary = createAsyncThunk(
  'api/modifyshouldCostRows',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', '/shouldCostSummary/kpi', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)


export const downloadSummary = createAsyncThunk(
  'api/download-summary',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', '/v1/scenarioProjectData/downloadFile', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)