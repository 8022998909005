import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const getSectionDetailsAsync = createAsyncThunk(
  'should-cost/bill-of-material/get-section-details',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', `/v1/sectionDetails${payload}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const createConversion = createAsyncThunk(
  'should-cost/bill-of-materials/create-conversion',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/subsection`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const createSpecification = createAsyncThunk(
  'should-cost/bill-of-materials/create-specification',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/subsection`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const updateSpecification = createAsyncThunk(
  'should-cost/bill-of-materials/update-specification',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/updateSubSectionDetails`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const deleteSpecification = createAsyncThunk(
  'should-cost/bill-of-materials/delete-specification',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/deleteProjectData`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const deleteConversion = createAsyncThunk(
  'should-cost/bill-of-materials/delete-conversion',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/conversion/subsection/delete`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  },
);

export const updateProjectData = createAsyncThunk(
  'should-cost/bill-of-materials/update-project-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/updateProjectData`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const deleteProjectData = createAsyncThunk(
  'should-cost/bill-of-materials/delete-project-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/projectDataDelete`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getFinalFormula = createAsyncThunk(
  'should-cost/bill-of-materials/get-final-formula',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/finalFormula`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getProjectDetails = createAsyncThunk(
  'should-cost/bill-of-materials/get-project-details',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', `/v1/projectData`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getFormulaItem = createAsyncThunk(
  'should-cost/bill-of-materials/get-formula-item',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', 'v1/formulaItem', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)