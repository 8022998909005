import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'

export const getdutytariffLibAsync = createAsyncThunk(
  'libraries/dutyandtariff',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', '/v1/tariff', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
