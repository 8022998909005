import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosClient } from 'src/redux/AxiosClient'
import {
  skuManagementApiRoutes,
  uploadApiRoutes,
  poTrendsApiRoutes,
  createShouldAPIRoutes,
  commodityApiRoutes,
  viewShouldCostApiRoutes,
  attachModelApiRoutes
} from '../constants/serviceConstants/shouldCostAIBased.apiRoutes'

export const getFiltersDataAsync = createAsyncThunk(
  'should-cost-ai-based/should-cost-get-filter-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', skuManagementApiRoutes.getFilters, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
export const getKpisAsync = createAsyncThunk(
  'should-cost-ai-based/should-cost-get-kpis-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', skuManagementApiRoutes.getKPIs, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getSkuTableDataAsync = createAsyncThunk(
  'should-cost-ai-based/should-cost-get-sku-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', skuManagementApiRoutes.getSkuTable, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

//upload sku master file
export const validateFileUploadsync = createAsyncThunk(
  'shouldCostAIBased/uploadTemplate',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', uploadApiRoutes.validateFile, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
      isRejectWithErrorMsg: true,
    })
  },
)
//finish upload sku master file
export const fileUploadAsync = createAsyncThunk(
  'shouldCostAIBased/finishUploadTemplate',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', uploadApiRoutes.uploadFile, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
      isRejectWithErrorMsg: true,
    })
  },
)
// download sku master and po standard template
export const downloadFileTemplateAsync = createAsyncThunk(
  'shouldCostAIBased/downloadFileTemplate',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', uploadApiRoutes.downloadFile, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
      isRejectWithErrorMsg: true,
    })
  },
)

export const getViewShouldCostDataAsync = createAsyncThunk(
  'should-cost-ai-based/get-should-cost-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', '/v1/should-cost-analysis/get-shouldcost-data', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const setShouldCostFinalAsync = createAsyncThunk(
  'should-cost-ai-based/set-should-cost-final',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', '/v1/should-cost-analysis/set-final-project', payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

// Get PO Trends Data for SKUs
export const getPOTrendsDataAsync = createAsyncThunk(
  'shouldCostAIBased/poTrendsData',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', poTrendsApiRoutes.getTrendsData, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

// Get PO Trends Line Chart Data for SKUs
export const getPOTrendsLineChartDataAsync = createAsyncThunk(
  'shouldCostAIBased/poTrendsLineChartData',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', poTrendsApiRoutes.getTrendsLineChartData, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getCreateViewDataAsync = createAsyncThunk(
  'shouldCostAIBased/createShouldCostView',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', createShouldAPIRoutes.getCreateShouldCostView, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const getVendorDataAsync = createAsyncThunk(
  'shouldCostAIBased/getVendorList',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', createShouldAPIRoutes.getVendorList, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const createShouldCostModelDataAsync = createAsyncThunk(
  'shouldCostAIBased/createShouldCostModel',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', createShouldAPIRoutes.createShouldCostModel, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

export const editShouldCostModelDataAsync = createAsyncThunk(
  'shouldCostAIBased/editShouldCostModel',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', createShouldAPIRoutes.editShouldCostModel, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

// Get Commodity data
export const getCommodityDataAsync = createAsyncThunk(
  'shouldCostAIBased/commodity-data',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', commodityApiRoutes.commodityData, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

// Get Countries data
export const getCountriesDataAsync = createAsyncThunk(
  'shouldCostAIBased/getCountriesData',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('GET', createShouldAPIRoutes.getCountries, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

// Get Should Cost Model data
export const getShouldCostModelDataAsync = createAsyncThunk(
  'shouldCostAIBased/getShouldCostModelData',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', createShouldAPIRoutes.getShouldCostModels, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

// Get Should Cost Breakdown
export const getShouldCostBreakdownDataAsync = createAsyncThunk(
  'shouldCostAIBased/getShouldCostBreakdownData',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', createShouldAPIRoutes.getShouldCostBreakdown, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

// Get Commodity KPIs
export const getCommodityKPIsAsync = createAsyncThunk(
  'shouldCostAIBased/commodity-kpis',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', commodityApiRoutes.commodityKPIs, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

//get mini ifa trendline

export const getIfaTrendsLineChartDataAsync = createAsyncThunk(
  'shouldCostAIBased/poTrendsLineChartData',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', viewShouldCostApiRoutes.trendLine, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

//check for mini ifa calculation
export const checkIfaProgressAsync = createAsyncThunk(
  'shouldCostAIBased/checkIfaProgressAsync',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', viewShouldCostApiRoutes.checkIfaProgress, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

//Download should Cost
export const downloadShouldCostAsync = createAsyncThunk(
  'shouldCostAIBased/downloadShouldCost',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', viewShouldCostApiRoutes.downloadShouldCost, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)

// get sku data for attaching model
export const getSkuDataAsync = createAsyncThunk(
  'shouldCostAIBased/getSkuDataAsync',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient('POST', attachModelApiRoutes.getSkuData, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    })
  },
)
