import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getFiltersAsync } from 'src/redux/async'
import { getFiltersSearchSuppliers } from './rfi-rfp.async'

const initialState = {
    filtersLoader: false,
    filtersData: [],
    filtersError: '',
}

export const SupplierSearchFilterSlice = createSlice({
    name: 'Supplier-Search-Filters',
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(getFiltersSearchSuppliers.pending), (state) => {
            state.filtersLoader = true
        })
        builder.addMatcher(isAnyOf(getFiltersSearchSuppliers.fulfilled), (state, action) => {
            state.filtersLoader = false
            state.filtersData = action.payload
        })
        builder.addMatcher(isAnyOf(getFiltersSearchSuppliers.rejected), (state, action) => {
            state.filtersLoader = false
            state.filtersError = action.payload.message
        })
    },
})

export default SupplierSearchFilterSlice.reducer