import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { getRiskScoresAsync } from 'src/redux/async/vcn/vcn.async'

const initialState = {
  getRiskScoresLoader: false,
  getRiskScoresData: [],
  getRiskScoresError: '',
}

export const getRiskScoresSlice = createSlice({
  name: 'vcn-exposure/getRiskScoresSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getRiskScoresAsync.pending), (state) => {
      state.getRiskScoresLoader = true
    })
    builder.addMatcher(isAnyOf(getRiskScoresAsync.fulfilled), (state, action) => {
      state.getRiskScoresLoader = false
      state.getRiskScoresData = action.payload
    })
    builder.addMatcher(isAnyOf(getRiskScoresAsync.rejected), (state, action) => {
      state.getRiskScoresLoader = false
      state.getRiskScoresError = action.payload.message
    })
  },
  reducers: {
    resetGetRiskScores: () => initialState
  },
})
export const { resetGetRiskScores } = getRiskScoresSlice.actions
export default getRiskScoresSlice.reducer
