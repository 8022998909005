import React, { Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes, useLocation } from 'react-router-dom'
import { msalInstance } from 'src'
import './scss/style.scss'
import { appInsights } from './AppInsights'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const signIn = async () => {
    try {
      const loginResponse = await msalInstance.loginPopup()
      console.log('Login successful!')
      if (loginResponse.account) {
        if (!appInsights?.core?.isInitialized()) {
          appInsights?.clearAuthenticatedUserContext()
          appInsights.getCookieMgr().set('ai_user', loginResponse.account.username)
          appInsights.getCookieMgr().set('ai_session', loginResponse.account.name)
          appInsights.loadAppInsights()
        }
        // await appInsights.setAuthenticatedUserContext(
        //   loginResponse.account.name,
        //   loginResponse.account.username,
        //   true,
        // )
        // await appInsights.context.user.update(loginResponse.account.username)
      }

      await msalInstance.setActiveAccount(loginResponse.account)

      return {
        status: 200,
        data: localStorage,
      }
    } catch (error) {
      console.log('Login failed!', error)
      return error.message
    }
  }

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/" name="Login Page" element={<Login signIn={signIn} />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
